import { Pipe, PipeTransform } from '@angular/core';
import {CommonConfig} from '../util/common-config';

@Pipe({
  name: 'gradePipe'
})
export class GradePipe implements PipeTransform {

  transform(value) {
    const gradeList = CommonConfig.getGradeList();
    const item = gradeList.filter(i => i.value === parseInt(value, 0));
    if (item && item.length > 0) {
      return item[0].label;
    } else {
      return '--';
    }
  }

}
