import { ComponentRef, Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {CommoditySelectComponent} from '../../component/commodity-select/commodity-select.component';

@Injectable()
export class CommoditySelectService {
  private resourceSelectService: ComponentRef<CommoditySelectComponent> | any;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}
  start(listData: any, screenType?: any ) {
    this.overlayRef = this.overlay.create();
    this.resourceSelectService = this.overlayRef.attach(new ComponentPortal(CommoditySelectComponent));
    this.resourceSelectService.instance.resourceInto(listData, screenType);
    this.resourceSelectService.instance.closeSelect.subscribe( () => {
      this.resourceSelectService.destroy();
    });
  }

  /**
   * 关联资源
   */
  selectCommodity(selectedList) {
    return new Promise((resolve, reject) => {
      this.overlayRef = this.overlay.create();
      this.resourceSelectService = this.overlayRef.attach(new ComponentPortal(CommoditySelectComponent));
      this.resourceSelectService.instance.resourceInto(selectedList);
      this.resourceSelectService.instance.resourceListChange.subscribe( (resourceList) => {
        resolve(resourceList);
        this.resourceSelectService.destroy();
      });
    });
  }

}
