export default {
  okTest: 'okTest',
  cancel: 'cancel',
  add: 'add',
  addEvaluate: 'add evaluate',
  edit: 'edit',
  editEvaluate: 'edit evaluate',
  delete: 'delete',
  areYouSureToDeleteTheEvaluation: 'Are you sure to delete the evaluation?',
  evaluationDeletedWillNotBeRestoredPleaseBeCarefulOperation: 'Evaluation deleted, will not be restored, please be careful operation',
  addTopic: 'add topic',
  sureToDeleteTheSubject: 'Sure to delete the subject?',
  afterTheTopicIsDeletedItCannotBeRestoredPleaseBeCareful: 'After the topic is deleted, it cannot be restored. Please be careful',
  warmReminderPleaseLightUp: 'Warm reminder: please light up',
  markTheCorrectAnswer: 'Mark the correct answer',
  pleaseEnterOptionsOne: 'please Enter Options One',
  pleaseEnterOptionsTwo: 'please Enter Options Two',
  pleaseEnterOptionsThree:  'please Enter Options Three',
  pleaseEnterOptionsFour:  'please Enter Options Four',
};

