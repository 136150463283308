export default {
  delete: '删除',
  add: '新增',
  sureDeleteTitle: '确定删除资源？',
  sureDeleteQrTitle: '确定删除二维码？',
  sureDeleteContent: '资源删除后，将不可恢复，请谨慎操作',
  sureDeleteQrContent: '二维码删除后，将不可恢复，请谨慎操作',
  recording: '浏览上下架记录',
  moveUp: '上移',
  moveDown: '下移',
  submit: '发布',
  cancel: '取消',
  enterPrice: '请输入价格',
  pricePrompt: '(不设置时默认为0)',
  uploadImgPrompt: '(上传图片最小尺寸为120*156px)',
  localUpload: '本地上传',
  fileLibraryUpload: '文件库上传',
  addChapter: '添加章节',
  chooseResourceType: '请选择资源类型',
  addAudioBook: '新增有声书',
  addVideoResource: '新增微课视频',
  updateVideoResource: '修改微课视频',
  addEBook: '新增电子书',
  updateAudioBook: '修改有声书',
  updateEBook: '修改电子书',
  addPackage: '新增组合资源管理',
  updatePackage: '修改组合资源管理',
  chooseImg: '请上传.jpg/.jpeg/.png图片！',
  batchPublish: '批量上架',
  batchObtained: '批量下架',
  chosen: '已选择',
  chosenPublishNext: '个资源，确认将其上架',
  chosenObtainedNext: '个资源，确认将其下架',
  chosenBatchCharge:'个资源，确认将其开启收费',
  chosenBatchFree:'个资源，确认将其开启免费',
  edit: '编辑',
  audioBookDetail: '有声书详情',
  videoDetail: '微课视频详情',
  eBookDetail: '电子书详情',
  packageDetail: '套装资源管理详情',
  backToList: '返回列表',
  analysis: '解析',
  sure: '确定',
  linkResource: '关联资源',
  serialNumber: '序号',
  author: '作者',
  pleaseInputAuthor: '请输入作者名称',
  resourceGrade: '资源分级',
  operate: '操作',
  uploadState: '上传状态',
  fileName: '文件名',
  duration: '时长',
  resourceCover: '资源封面',
  resourceName: '资源名称',
  pleaseInputResourceName: '请输入资源名称',
  resourceCategory: '资源分类',
  resourceType: '资源类型',
  isRecommend: '是否推荐',
  isHot: '是否热门',
  isPay:'是否收费',
  shelfState: '上架状态',
  yes: '是',
  no: '否',
  shelf: '上架',
  obtained: '下架',
  price: '价格(￥)',
  priceOut: '价格',
  coverPicture: '封面图片',
  pressName: '出版社',
  pleaseInputPressName: '请输入出版社名称',
  remark: '备注',
  pleaseInputRemark: '请输入备注',
  pleaseChooseGrade: '请选择资源分级',
  introduction: '简介说明',
  InputIntroduction: '请输入简介说明',
  resourceChapterList: '章节列表',
  parsingState: '解析状态',
  resourceSize: '大小',
  wordCount: '字数(千字)',
  pleaseInputWordCount: '请输入字数',
  pleaseInputBookNumber: '请输入书号',
  bookNumber: '书号',
  eBookFile: '电子书文件',
  isShelf: '是否上架',
  addQrCode: '新增二维码',
  downloadQrCode: '下载二维码',
  downloadQrCodeTitle: '500*500像素',
  editQrCode: '修改二维码',
  linkAddress: '链接地址',
  linkStatus: '关联状态',
  isPublish: '是否发布',
  qrCode: '二维码',
  resourceDirectory: '资源目录',
  qrCodeErrorTitle: '当前二维码未关联资源',
  qrCodeErrorContent: '当前二维码未关联资源，请先关联资源后再进行发布',
  evaluateTag: '测评标签',
  placeholderTag: '请选择测评标签',
  suspensionButton:'实体书悬浮按钮',
  batchCharge:'批量收费',
  batchFree:'批量免费'
};
