export enum SpecialTopicType {
  /**
   * 线上
   */
  ONLINE = 1,
  /**
   * 线下
   */
  OFFLINE = 2,
  /**
   * 直播
   */
  LINE = 3,
  /**
   * 报名
   */
  APPLY = 4,

  /**
   * 聊天
   */
  CHAT = 5,
}
