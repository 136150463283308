import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {XcI18nModule} from './service/i18N';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {PaginationComponent} from './component/pagination/pagination.component';
import {FormComponent} from './component/form/form.component';
import {TreeNodeComponent} from './component/tree/tree-node.component';
import {TreeComponent} from './component/tree/tree.component';
import {TableComponent} from './component/table/table.component';
import {TableSearchComponent} from './component/table/table-search/table-search.component';
import {XcNzSelectModule} from './component/select';
import {SearchInputComponent} from './component/search-input/search-input.component';
import {SelectValuePipe} from './pipe/selectValue.pipe';
import {DynamicPipe} from './pipe/dynamic.pipe';
import {NavigationTranslatePipe} from './pipe/navigation-translate.pipe';
import {RuleUtil} from './util/rule-util';
import {AccessPermissionDirective} from './directive/access-permission.directive';
import {ModalService} from './service/modal/modal.service';
import {DateSelectInputComponent} from './component/date-select-input/date-select-input.component';
import {BreadcrumbComponent} from './component/breadcrumb/breadcrumb.component';
import {ResourceSelectComponent} from './component/resource-select/resource-select.component';
import {ResourceColumnConfigService} from './component/resource-select/resource-column-config.service';
import {ResourceSelectService} from './service/resource-select/resource-select.service';
import {PictureExhibitionComponent} from './component/picture-exhibition/picture-exhibition.component';
import {PictureExhibitionService} from './service/picture-exhibition/picture-exhibition.service';
import {TimeConversionPipe} from './pipe/timeConversion.pipe';
import {UploadingComponent} from './component/uploading/uploading.component';
import {UploadingImgComponent} from './component/uploading-img/uploading-img.component';
import {InfoAlertComponent} from './component/info-alert/info-alert.component';
import {MessageModalService} from './component/info-alert/message-modal.service';
import {CommonEditComponent} from './component/common-edit/common-edit.component';
import {QuillModule} from 'ngx-quill';
import {VideoConfigComponent} from './component/common-edit/video-config/video-config.component';
import {ResourceResultService} from './service/resource-select/resource-result-service';
import {HtmlPipe} from './pipe/html.pipe';
import {LinkGuideBookComponent} from './component/link-guide-book/link-guide-book.component';
import {AddTopicConfigService} from './component/add-topic/add-topic.config.service';
import {AsyncRuleUtil} from './util/async-rule-util';
import {SessionUtil} from './util/session-util';
import {ViewPictureComponent} from './component/view-picture/view-picture.component';
import {ViewPictureService} from './service/view-picture/view-picture.service';
import {AddTopicComponent} from './component/add-topic/add-topic.component';
import {PermissionDirective} from './directive/permission.directive';
import { ShelfRecordComponent } from './component/shelf-record/shelf-record.component';
import {UploadService} from './util/upload.service';
import { LinkActivityComponent } from './component/link-activity/link-activity.component';
import { ViewerjsComponent } from './component/viewerjs/viewerjs.component';
import {ViewerjsService} from './component/viewerjs/viewerjs.service';
import { KidsImageComponent } from './component/kids-image/kids-image.component';
import { SelectBookSingleComponent } from './component/select-book-single/select-book-single.component';
import {SelectBookSingleService} from './component/select-book-single/select-book-single.service';
import { SelectAuthorSingleComponent } from './component/select-author-single/select-author-single.component';
import {SelectAuthorSingleService} from './component/select-author-single/select-author-single.service';
import { SelectLiveRoomComponent } from './component/select-live-room/select-live-room.component';
import {SelectLiveRoomService} from './component/select-live-room/select-live-room.service';
import { EBookUploadComponent } from './component/e-book-upload/e-book-upload.component';
import { HTitleComponent } from './component/h-title/h-title.component';
import {DownloadService} from './util/download.service';
import {GradePipe} from './pipe/grade.pipe';
import { TopicInfoComponent } from './component/topic-info/topic-info.component';
import {AnswerPipe} from './pipe/answer.pipe';
import { StatisticsTitleComponent } from './component/statistics-title/statistics-title.component';
import { PictureBookImgComponent } from './component/picture-book-img/picture-book-img.component';
import {PictureBookImgService} from './component/picture-book-img/picture-book-img.service';
import { ChooseCouponComponent } from './component/choose-coupon/choose-coupon.component';
import { CommoditySelectComponent } from './component/commodity-select/commodity-select.component';
import {CommoditySelectService} from './service/commodity-select/commodity-selece.service';
import {CommodityResultService} from './service/commodity-select/commodity-result-service';
import {CommodityColumnConfigService} from './component/commodity-select/commodity-column-config.service';
import { AuthorSelectComponent } from './component/author-select/author-select.component';
import {AuthorSelectService} from './component/author-select/author-select.service';
import {QrGuideBookComponent} from './component/qr-guide-book/qr-guide-book.component';
import {QrGuideBatchComponent} from './component/qr-guide-batch/qr-guide-batch.component';

const COMPONENT = [
  PaginationComponent,
  FormComponent,
  TreeNodeComponent,
  CommoditySelectComponent,
  TreeComponent,
  TableComponent,
  TableSearchComponent,
  SearchInputComponent,
  DateSelectInputComponent,
  BreadcrumbComponent,
  ResourceSelectComponent,
  PictureExhibitionComponent,
  UploadingComponent,
  UploadingImgComponent,
  InfoAlertComponent,
  CommonEditComponent,
  VideoConfigComponent,
  AddTopicComponent,
  LinkGuideBookComponent,
  LinkActivityComponent,
  ViewPictureComponent,
  ViewerjsComponent,
  SelectBookSingleComponent,
  SelectAuthorSingleComponent,
  SelectLiveRoomComponent,
  EBookUploadComponent,
  HTitleComponent,
  StatisticsTitleComponent,
  TopicInfoComponent,
  ChooseCouponComponent,
  PictureBookImgComponent,
  AuthorSelectComponent,
  QrGuideBookComponent,
  QrGuideBatchComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    XcI18nModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    XcNzSelectModule,
    QuillModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    AccessPermissionDirective,
    PermissionDirective,
    ...COMPONENT,
    XcNzSelectModule,
    DynamicPipe,
    HtmlPipe,
    GradePipe,
    AnswerPipe,
    NavigationTranslatePipe,
    TimeConversionPipe,
    ShelfRecordComponent,
    LinkActivityComponent,
    ChooseCouponComponent,
    KidsImageComponent
  ],
  providers: [RuleUtil, AsyncRuleUtil, SessionUtil, ModalService, ResourceColumnConfigService, CommodityColumnConfigService, ResourceSelectService,
    CommoditySelectService, CommodityResultService,
    PictureExhibitionService, MessageModalService, ResourceResultService, AddTopicConfigService, ViewPictureService,
    UploadService, ViewerjsService, SelectBookSingleService, SelectAuthorSingleService, SelectLiveRoomService, DownloadService, PictureBookImgService, AuthorSelectService],
  declarations: [...COMPONENT, DynamicPipe, SelectValuePipe, NavigationTranslatePipe, PermissionDirective,
    AccessPermissionDirective,
    DateSelectInputComponent,
    ResourceSelectComponent,
    PictureExhibitionComponent,
    TimeConversionPipe,
    InfoAlertComponent,
    HtmlPipe,
    GradePipe,
    AnswerPipe,
    ViewPictureComponent,
    ShelfRecordComponent,
    LinkActivityComponent,
    CommoditySelectComponent,
    ViewerjsComponent, KidsImageComponent, SelectBookSingleComponent, SelectAuthorSingleComponent, SelectLiveRoomComponent, EBookUploadComponent, HTitleComponent, TopicInfoComponent, StatisticsTitleComponent, PictureBookImgComponent, ChooseCouponComponent, AuthorSelectComponent],
  entryComponents: [...COMPONENT]
})

export class SharedModule {
}
