import CryptoJS from 'crypto-js';
const key = 'kidsreading12345';
export class CommonAesService {


  constructor() { }

  /**
   * 通用加密
   * param value
   */
  static encrypt(value) {
    const keys = CryptoJS.enc.Utf8.parse(key);
    const values = CryptoJS.enc.Utf8.parse(value);
    return CryptoJS.AES.encrypt(values, keys, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  /**
   * 通用解密
   * param value
   */
  static decrypt(value) {
    if (value) {
      const keys = CryptoJS.enc.Utf8.parse(key);
      const  bytes = CryptoJS.AES.decrypt(value, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return bytes.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }
}
