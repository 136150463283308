export default {
  confirm: 'confirm',
  cancel: 'cancel',
  options: 'options',
  saving: 'saving...',
  loading: 'loading...',
  operate: 'operate',
  operateSuccess: 'operate success',
  more: 'more',
  serialNumber: 'serial number',
  reset: 'reset',
  selected: 'selected',
  unSelect: 'unSelect',
  count: 'count',
  noData: 'no data',
  choosePosition: 'choose position',
  levelLimit: 'You can only add 5 levels at most!',
  levelEdit: 'Can not change the original level!',
  setAreaMsg: 'Can not remove the current area has been associated with the facility, the facility will cause no regional ownership!',
  deleteBtn: 'delete',
  edit: 'edit',
  revert: 'revert',
  assign: 'assign',
  viewPhoto: 'view photo',
  beOffLineMsg: 'You have been offline!',
  beDeleteMsg: 'Your login account has been forcibly deleted!',
  turnBackConfirm: 'turnBack Confirm',
  rebuild: 'rebuild',
  nameRuleMsg: 'Can only input Chinese, English, Numbers, Spaces and commonly used special symbols!',
  notEmpty: 'The input value cannot be empty!',
  permissionMsg: 'no permission',
  expectCompleteTimeMoreThanNowTime: 'expect complete time more than now time',
  nameExists: 'name exists！',
  phoneNumberExists: 'phoneNumber exists!',
  emailExists: 'email exists!',
  cancelText: 'cancel',
  okText: 'ok',
  logOutMsg: 'Whether to log out?',
  openLockMsg: 'open lock msg',
  systemMsg: 'system msg',
  clickSelect: 'click select',
  deviceBeOffLineMsg: 'This account has been logged on to other devices!',
  addressRequired: 'Detailed address is required!',
  noPictureNow: 'no picture now',
  one: 'one',
  two: 'two',
  three: 'three',
  four: 'four',
  five: 'five',
  level: 'level',
  tempAuthMsg: 'New provisional authorizations need to be audited!',
  timeMsg: 'The start time cannot be longer than the end time. Please select again!',
  issueImg: 'issue Img',
  contentOfFeedback: 'content Of Feedback',
  cellPhoneNumber: 'cell Phone Number',
  userName: 'user Name',
  feedbackTime: 'feedback Time',
  isNoDispose: 'is No Dispose',
  notNum: 'please input Number',
};
