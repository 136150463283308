import {Injectable} from '@angular/core';
import { ColumnManageInterface } from './column-manage.interface';
import axios from '../../http/http.service';

import {
  QUERY_COLUMN_IN_FO_LIST,
  QUERY_BANNER_IN_FO_LIST,
  QUERY_BANNER_IN_FO_BY_ID,
  ADD_BANNER_IN_FO,
  ADD_BANNER_RESOURCE,
  MODIFY_BANNER_IN_FO,
  DELETE_BANNER_IN_FO_BATCH,
  DELETE_BANNER_RESOURCE_BATCH,
  MODIFY_BANNER_STATE,
  MODIFY_BANNER_SORT,
  MODIFY_COLUMN_STATE,
  ADD_COLUMN_IN_FO,
  DELETE_COLUMN_IN_FO,
  MODIFY_COLUMN_IN_FO_INIT,
  MODIFY_COLUMN_INDEX,
  MODIFY_COLUMN_IN_FO
} from '../column-request-url';

@Injectable()
export class ColumnManageService implements ColumnManageInterface {

  /**
   * Banner 列表
   */
  queryBannerInfoList() {
    return axios.post( `${ QUERY_BANNER_IN_FO_LIST }`);
  }

  /**
   * Banner 详情页面 通过id 查询详细信息
   */
  queryBannerInfoById(bannerId: string) {
    return axios.post( `${ QUERY_BANNER_IN_FO_BY_ID }` , bannerId);
  }

  /**
   * 新增 Banner  传入图片
   */
  addBannerInfo(imgData: any) {
    return axios.post( `${ ADD_BANNER_IN_FO }` , imgData);
  }
  /**
   * 添加Banner 资源
   */
  addBannerResource(body) {
    return axios.post( `${ ADD_BANNER_RESOURCE }` , body);
  }
  /**
   * 编辑 Banner
   */
  modifyBannerInfo(body) {
    return axios.post( `${ MODIFY_BANNER_IN_FO }` , body);
  }
  /**
   * 删除 Banner
   */
  deleteBannerInfoBatch(body) {
    return axios.post( `${ DELETE_BANNER_IN_FO_BATCH }` , body);
  }

  /**
   * 移除Banner 资源
   */
  deleteBannerResourceBatch(body) {
    return axios.post( `${ DELETE_BANNER_RESOURCE_BATCH }` , body);
  }

  /**
   * 修改banner状态 是否显示
   */
  modifyBannerState(body) {
    return axios.post( `${ MODIFY_BANNER_STATE }` , body);
  }

  /**
   * banner顺序
   */
  modifyBannerSort(body) {
    return axios.post( `${ MODIFY_BANNER_SORT }` , body);
  }

  /**
   * 栏目配置 列表
   */
  queryColumnInfoList() {
    return axios.get( `${ QUERY_COLUMN_IN_FO_LIST }`);
  }

  /**
   * 栏目配置 修改状态
   */
  modifyColumnState(body) {
    return axios.post( `${ MODIFY_COLUMN_STATE }`, body);
  }

  /**
   * 栏目配置 新增
   */
  addColumnInfo(body) {
    return axios.post( `${ ADD_COLUMN_IN_FO }`, body);
  }

  /**
   * 栏目配置 编辑
   */
  modifyColumnInfo(body) {
    return axios.post( `${ MODIFY_COLUMN_IN_FO }`, body);
  }
  /**
   * 栏目配置 删除
   */
  deleteColumnInfo(body) {
    return axios.delete( `${ DELETE_COLUMN_IN_FO }`, { data: body} );
  }

  /**
   * 栏目配置 通过ID查询数据
   */
  modifyColumnInfoInit(body) {
    return axios.post( `${ MODIFY_COLUMN_IN_FO_INIT }`, body ,
      {headers : {
        'Content-Type': 'application/json; charset=UTF-8'
      }}
    );
  }

  /**
   * 栏目配置 上移或者下移
   */
  modifyColumnIndex(body) {
    return axios.post( `${ MODIFY_COLUMN_INDEX }`,  body );
  }
}
