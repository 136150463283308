export default {
  'inputPlaceholder': 'Please fill out the',
  'selectPlaceholder': 'please choose',
  'requiredMsg': 'This is mandatory!',
  'minLengthMsg': 'The minimum input!',
  'maxLengthMsg': 'Most input',
  'minMsg': 'Not less than',
  'maxMsg': 'Is not greater than',
  'emailMsg': 'Mailbox format error!',
  'patternMsg': 'Regular validation error!',
  'count': 'count!',
  'exclamation': '!',
  'reset': 'reset',
  'nameExistError': 'such name is exist',
};
