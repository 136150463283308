import {Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import {NzI18nService} from 'ng-zorro-antd';
import { CommodityColumnConfigService } from './commodity-column-config.service';
import {TableConfig} from '../../entity/tableConfig';
import {PageBean} from '../../entity/pageBean';
import axios from '../../../core-module/http/http.service';
import { getResourceType } from '../../../business-module/column-manage/column-config';
import { ResourceResultService } from '../../service/resource-select/resource-result-service';
import {Result} from '../../entity/result';
import {QueryCondition} from '../../entity/queryCondition';
import {TableBasic} from '../table/table.basic';
import {commodityApiUrl} from '../../../business-module/e-commerce-management/api-url';
import {CommodityResultService} from '../../service/commodity-select/commodity-result-service';

@Component({
  selector: 'app-commodity-select',
  templateUrl: './commodity-select.component.html',
  styleUrls: ['./commodity-select.component.scss']
})
export class CommoditySelectComponent extends TableBasic implements OnInit {
  display = {
    table: false,
  };
  _dataSetSelect = [];
  // 初始化进入后 备份数据 用于点击确定后 操作
  _dataSetSelectBack = [];
  tableConfigSelect: TableConfig;
  pageBeanSetSelect: PageBean = new PageBean(10, 1, 1);
  // 表格通用查询条件
  queryCondition: QueryCondition = new QueryCondition();
  @ViewChild('resourceNameTemp') resourceNameTemp: TemplateRef<any>;
  @Output() closeSelect = new EventEmitter();
  @Output() resourceListChange = new EventEmitter();
  constructor(
    public $nzI18n: NzI18nService,
    public $columnConfigService: CommodityColumnConfigService,
    public $resourceResultService: CommodityResultService
  ) {
    super($nzI18n);
  }
  ngOnInit() {
  }

  /**
   * 关闭弹框
   */
  closesPopUp() {
    this.display.table = false;
    this.closeSelect.emit();
  }

  /**
   * 从服务里面进入
   * type 为标记的每个页面
   * listData 为勾选的数据
   * screenType  为筛选类型  如果有就只显示视频
   */
  resourceInto(listData, filter?) {
    this.display.table = true;
    if (filter) {
      this.queryCondition.filterConditions = filter;
    }
    this.initTableConfig();
    this.refreshData();
    this._dataSetSelectBack = this.clone(listData);
    this._dataSetSelect = this.clone(listData);
    this.pageBeanSetSelect.Total = this._dataSetSelect.length;
  }

  /**
   * 克隆
   */
  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }
  /**
   * 初始化表格配置
   * tableConfig 为左侧的列表
   * tableConfigSelect 为右侧的列表
   */
  public initTableConfig() {
    this.tableConfig = {
      scroll: { x: '500px', y: '300px' },
      noIndex: true,
      notShowPrint: true,
      columnConfig: this.$columnConfigService.getTableResourceListColumnConfig(this.resourceNameTemp),
      showPagination: true,
      showSearch: false,
      showSearchSwitch: true,
      showSizeChanger: true,
      topButtons: [],
      operation: [],
      leftBottomButtons: [],
      rightTopButtons: [],
      handleSelect: (data, currentItem) => {
        if ( !currentItem ) {
          // 全局勾选
          switch (data.length) {
            case 0:
              // 全局取消
              const checkData = [];
              const dataIds = this._dataSet.map(item => item.id );
              this._dataSetSelect.forEach(check => {
                if ( dataIds && dataIds.indexOf(check.id) === -1 ) {
                  checkData.push(check);
                }
              });
              this._dataSetSelect = checkData;
              break;
            default:
              // 全局勾选
              const _dataSetSelectIds = this._dataSetSelect.map(item => item.id );
              data.forEach(check => {
                if ( _dataSetSelectIds && _dataSetSelectIds.indexOf(check.id) === -1 ) {
                  this._dataSetSelect.push(check);
                }
              });
          }
          this.pageBeanSetSelect.Total = this._dataSetSelect.length;
          this.pageBeanSetSelect.pageIndex = 1;
          this.pageBeanSetSelect.pageSize = 10;
        } else if ( currentItem.checked ) {
          // 单个勾选
          const _dataSetSelectIds = this._dataSetSelect.map(item => item.id );
          if ( _dataSetSelectIds && _dataSetSelectIds.length ) {
            data.forEach(check => {
              if ( _dataSetSelectIds && _dataSetSelectIds.indexOf(check.id) === -1 ) {
                this._dataSetSelect.push(check);
              }
            });
          } else {
            // 如果右侧的勾选栏是空的直接添加
            this._dataSetSelect.push(...data);
          }
          this.pageBeanSetSelect.Total = this._dataSetSelect.length;
          this.pageBeanSetSelect.pageIndex = 1;
          this.pageBeanSetSelect.pageSize = 10;
        } else {
          // 单个 取消勾选
          this._dataSetSelect = this._dataSetSelect.filter(item => item.id !== currentItem.id );
        }
        this.pageBeanSetSelect.Total = this._dataSetSelect.length;
        this.pageBeanSetSelect.pageIndex = 1;
        this.pageBeanSetSelect.pageSize = 10;
        this._dataSetSelect = this._dataSetSelect.slice();
        // this._dataSetSelectBack  = this.clone(this._dataSetSelect.slice());
      }
    };

    if (this.queryCondition.filterConditions.length > 0) {
      this.tableConfig.columnConfig.map(item => {
        if (item.key === 'resourceTypeName') {
          item.searchable = false;
        }
      });
    }
    this.tableConfigSelect = {
      scroll: { x: '500px', y: '300px' },
      noIndex: true,
      notShowPrint: true,
      columnConfig: this.$columnConfigService.getTableResourceListSelectColumnConfig(),
      // showPagination: true,
      showSearch: false,
      showSearchSwitch: true,
      showSizeChanger: true,
      topButtons: [],
      operation: [
        {
          text: '删除',
          className: 'icon-delete red-icon',
          handle: (currentIndex) => {
            this._dataSetSelect = this._dataSetSelect.filter(item => item.commodityName !== currentIndex.commodityName  );
            this._dataSet.forEach(item => {
              if ( item.commodityName ===  currentIndex.commodityName ) { item.checked = false; }
            });
          }
        },
      ],
      leftBottomButtons: [],
      rightTopButtons: [],
    };
  }

  /**
   * 点击 确定
   */
  save() {
    this.closesPopUp();
    // 最后的列表数据 id的数据
    const _dataSetSelectId = this._dataSetSelect.map(item => item.id );
    // 初始化时 列表备份数据 id的数据
    const _dataSetSelectBackId = this._dataSetSelectBack.map(item => item.id );
    // 当数据存在于右侧列表中_dataSetSelectId  但不存在于初始化列表数据 _dataSetSelectBackId 时 为新增的数据
    const addIdsList = [];
    _dataSetSelectId.forEach(item => {
      if ( _dataSetSelectBackId.indexOf(item) === -1 ) {
        addIdsList.push(item);
      }
    });
    // 当数据不存在与右侧列表中 _dataSetSelectId  但存在于初始化列表数据_dataSetSelectBackId时 为删除的数据
    const deleteIdsList = [];
    _dataSetSelectBackId.forEach(item => {
      if ( _dataSetSelectId.indexOf(item) === -1 ) {
        deleteIdsList.push(item);
      }
    });
    const body = {
      addList: addIdsList,
      deleteList: deleteIdsList,
    };
    this.resourceListChange.emit(this._dataSetSelect);
    this.$resourceResultService.getCommoditySubject.next(body);
  }
  /**
   * 列表请求
   */
  refreshData() {
    this.tableConfig.isLoading = true;
    this.queryCondition.filterConditions.push({
      'filterField': 'shelfState',
      'operator': 'eq',
      'filterValue': 1
    });
    axios.post(commodityApiUrl.queryCommodityList, this.queryCondition).then((result: Result) => {
      if ( result.code === '0' ) {
        this._dataSet = result['data'].data.map( item => {
          this._dataSetSelect.forEach( data => {
            if ( item.id === data.id ) {
              item.checked = true;
            }
          });
          return item;
        });
        this.tableConfig.isLoading = false;
        this.pageBean.Total = result['data'].totalCount;
        this.pageBean.pageSize = result['data'].size;
        this.pageBean.pageIndex = result['data'].pageNum;
      }
    });
  }

  /**
   * 右侧列表前端筛选
   */
  handleSearchSelected(event) {
    if ( event && event.length ) {
      // 有筛选数据时进入
      event.forEach( item => {
        this._dataSetSelect = this._dataSetSelectBack.filter(items => {
          return items[item.filterField].includes(item.filterValue);
        });
      });
    } else {
      // 重置表格
      this._dataSetSelect = this._dataSetSelectBack;
    }
  }
}
