import { ComponentRef, Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {InfoAlertComponent} from './info-alert.component';

@Injectable()
export class MessageModalService {
  private InfoModal: ComponentRef<InfoAlertComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  /**
   * 警示确认框
   */
  confirm(data: {modalTitle, modalContent?, handleOk?()}) {
    this.overlayRef = this.overlay.create();
    this.InfoModal = this.overlayRef.attach(new ComponentPortal(InfoAlertComponent));
    this.InfoModal.instance.confirmModal(data);
    this.InfoModal.instance.cancel.subscribe(() => {
      this.InfoModal.destroy();
    });
  }

  /**
   * 错误提示框
   */
  error(data: {modalTitle, modalContent?, handleOk?()}) {
    this.overlayRef = this.overlay.create();
    this.InfoModal = this.overlayRef.attach(new ComponentPortal(InfoAlertComponent));
    this.InfoModal.instance.errorModal(data);
    this.InfoModal.instance.cancel.subscribe(() => {
      this.InfoModal.destroy();
    });
  }
  warn(data: {modalTitle, modalContent?, handleOk?()}) {
    this.overlayRef = this.overlay.create();
    this.InfoModal = this.overlayRef.attach(new ComponentPortal(InfoAlertComponent));
    this.InfoModal.instance.warnModal(data);
    this.InfoModal.instance.cancel.subscribe(() => {
      this.InfoModal.destroy();
    });
  }
  success(data: {modalTitle, modalContent?, handleOk?()}) {
    this.overlayRef = this.overlay.create();
    this.InfoModal = this.overlayRef.attach(new ComponentPortal(InfoAlertComponent));
    this.InfoModal.instance.successModal(data);
    this.InfoModal.instance.cancel.subscribe(() => {
      this.InfoModal.destroy();
    });
  }
}
