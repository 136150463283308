import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-title',
  templateUrl: './h-title.component.html',
  styleUrls: ['./h-title.component.scss']
})
export class HTitleComponent implements OnInit {

  @Input() pageTitle = '';
  @Input() showGoBack = false;
  constructor() { }

  ngOnInit() {
  }

  goBack() {
    window.history.go(-1);
  }
}
