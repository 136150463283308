import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BasicConfig} from '../form/basic-config';
import {Router} from '@angular/router';
import {NzI18nService} from 'ng-zorro-antd';
import {ModalService} from '../../service/modal/modal.service';
import axios from '../../../core-module/http/http.service';
import * as api from '../../../business-module/evaluate/api-url';
import {Result} from '../../entity/result';
import { AddTopicConfigService } from './add-topic.config.service';
import * as _ from 'lodash';
/**
 * 添加题目组件
 */
@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.scss']
})

export class AddTopicComponent extends BasicConfig implements OnInit {
  /**
   * 'mysterious' 为神秘问题
   * 'evaluate' 测评管理
   */
  _type: 'mysterious' | 'evaluate';
  @Input() set type( type ) {
    this._type = type;
    if ( type === 'evaluate' ) {
      // 表单初始化
      this.formColumn = this.$columnConfigService.addTopicFormConfig(this.chooseA, this.chooseB, this.chooseC, this.chooseD);
    } else if (type === 'mysterious' ) {
      this.formColumn = this.$columnConfigService.addMysteriousFormConfig(this.numTemp, this.chooseA, this.chooseB, this.chooseC, this.chooseD);
    }

  }
  //  修改题目时传入数据
  @Input() set topicData(params) {
    if (params) {
      this.selectAnswer = '';
      setTimeout( () => {
        this.formStatus.resetData(params);
        this.topicValue = params.grade;
        this.topicId = params.id;
        this.valueA = params.optionOne;
        this.valueB = params.optionTwo;
        this.valueC = params.optionThree;
        this.valueD = params.optionFour;
        this.selectAnswer = params.answer;
        this.formStatus.resetControlData('chooseA', this.valueA);
        this.formStatus.resetControlData('chooseB', this.valueB);
        this.formStatus.resetControlData('chooseC', this.valueC);
        this.formStatus.resetControlData('chooseD', this.valueD);
        this.answer = {
          one: null,
          two: null,
          three: null,
          four: null,
        };
        switch (params.answer) {
          case 1 :
            this.answer.one = 1;
            break;
          case 2 :
            this.answer.two = 1;
            break;
          case 3 :
            this.answer.three = 1;
            break;
          case 4 :
            this.answer.four = 1;
            break;
        }
      }, 0);
    }
  }
  // 在新增状态下获取测评ID
  @Input() _readTestId;
  // 组件标题
  @Input() topicTitle;
  @Input() maxTopicValue;
  // 关闭该组件
  @Output() xcVisibleChange = new EventEmitter<boolean>();
  // 确定时发送数据
  @Output() topicSendData = new EventEmitter<any>();
  // 选项1~4input组件
  @ViewChild('chooseA') chooseA: TemplateRef<any>;
  @ViewChild('chooseB') chooseB: TemplateRef<any>;
  @ViewChild('chooseC') chooseC: TemplateRef<any>;
  @ViewChild('chooseD') chooseD: TemplateRef<any>;
  @ViewChild('inputNumElement') inputNumElement: ElementRef;
  @ViewChild('numTemp') numTemp: TemplateRef<any>;
  // 选项组件对应数据
  public valueA;
  public valueB;
  public valueC;
  public valueD;
  topicValue;
  // 在编辑状态下 保存题目ID
  topicId;
  answer = {
    one: null,
    two: null,
    three: null,
    four: null,
  };
  // 点击选择答案
  selectAnswer;
  constructor(
    public $router: Router,
    public $nzI18n: NzI18nService,
    public $message: ModalService,
    public $columnConfigService: AddTopicConfigService,
  ) {
    super($nzI18n);
    this.language = $nzI18n.getLocaleData('evaluate');
  }
  _xcVisible = false;

  get xcVisible() {
    return this._xcVisible;
  }

  @Input()
  set xcVisible(params) {
    this._xcVisible = params;
  }
  ngOnInit() {
    // // 表单初始化
    // this.formColumn = this.$columnConfigService.addTopicFormConfig(this.chooseA, this.chooseB, this.chooseC, this.chooseD);
  }
  /**
   * 选项一 选项二 是必填项 当值发生变化时触发
   */
  valueChange(type, data) {
    switch (type) {
      case 1:
        this.formStatus.resetControlData('chooseA', data);
        if ( !data ) {
          this.answer.one = null;
        }
        break;
      case 2:
        this.formStatus.resetControlData('chooseB', data);
        if ( !data ) {
          this.answer.two = null;
        }
        break;
      case 3:
        this.formStatus.resetControlData('chooseC', data);
        if ( !data ) {
          if (  this.answer.three || this.answer.four  ) {
            this.selectAnswer = '';
          }
          // 当选项3置空时，选项4置空 选项 3 4 都取消
          this.answer.three = null;
          this.valueD = '';
          this.answer.four = null;
          this.formStatus.resetControlData('chooseD', null);
        }
        break;
      case 4:
        this.formStatus.resetControlData('chooseD', data);
        if ( !data ) {
          this.answer.four = null;
        }
        break;
    }
    if (!data && this.selectAnswer === type) {
      this.selectAnswer = '';
    }
  }
  /**
   * 数字框
   */
  public onPriceChange(event) {
    this.formStatus.resetControlData('grade', event);
  }
  /**
   * 确定按钮
   */
  public save() {
    const alarmObj = this.formStatus.getData();
    switch (this._type) {
        // 神秘问题
        case 'mysterious':
          this.mysteriousSave(alarmObj);
        break;
    }
  }

  /**
   * 神秘问题的新增和编辑
   */
  mysteriousSave(alarmObj) {
    const body = {
      // 题干
      'stem': alarmObj.stem,
      // 分数
      'grade': alarmObj.grade,
      // 选项1
      'optionOne': alarmObj.chooseA,
      // 选项2
      'optionTwo': alarmObj.chooseB,
      // 选项3
      'optionThree': alarmObj.chooseC,
      // 选项4
      'optionFour': alarmObj.chooseD,
      // 答案（1/2/3/4）
      'answer': this.selectAnswer
    };
     this.topicSendData.emit(body);
     this.xcVisible = false;
  }

  /**
   * 选择正确答案
   */
  clickRate(select: string, selectAnswer: number) {
    if (this.selectAnswer === selectAnswer) {
      this.selectAnswer = null;
    } else {
      this.selectAnswer = selectAnswer;
      this.answer = {
        one: '',
        two: '',
        three: '',
        four: '',
      };
      this.answer[select] = 1;
    }
  }

  /**
   * 取消
   */
  cancel() {
    this.xcVisibleChange.emit(this._xcVisible);
  }
}
