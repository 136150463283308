import {CanActivate, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AccessGuardService} from './core-module/router-guard/access-guard.service';
import {CoreModule} from './core-module/core-module.module';
import {NotfoundComponent} from './business-module/notfound/notfound.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: 'notfound', component: NotfoundComponent},
  {path: 'login', loadChildren: './business-module/login/login.module#LoginModule'},
  {path: 'business', loadChildren: './business-module/business.module#BusinessModule', canActivateChild: [AccessGuardService]},
  {path: '**', redirectTo: '/notfound'}
];

@NgModule({
  imports: [
    CoreModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutes {
}
