const RESOURCE = '/ksr_manager';

export const  authorApiUrl = {
  // 获取页面上传ID
  queryWriterInfoList: `${RESOURCE}/writer/queryWriterInfoList`,
  // 推荐
  isRecommendWriter: `${RESOURCE}/writer/isRecommendWriter`,
  // 取消推荐
  noRecommendWriter: `${RESOURCE}/writer/noRecommendWriter`,
  // 删除作家
  deleteWriters: `${RESOURCE}/writer/deleteWriters`,
  // 新增作家
  insertWriter: `${RESOURCE}/writer/insertWriter`,
  // 修改作家
  updateWriter: `${RESOURCE}/writer/updateWriter`,
  // 根据id查询作家信息
  queryWriterInfoById: `${RESOURCE}/writer/queryWriterInfoById`,
  // 修改专题专书
  updateWriterRecommendResource: `${RESOURCE}/writer/updateWriterRecommendResource`,
};

export const articleApiUrl = {
  // 专栏文章列表
  queryWriterArticlesByCondition: `${RESOURCE}/writerArticle/queryWriterArticlesByCondition`,
  // 专栏文章上架
  putOnWriterArticle: `${RESOURCE}/writerArticle/putOnWriterArticle`,
  // 专栏文章下架
  pullOffWriterArticle: `${RESOURCE}/writerArticle/pullOffWriterArticle`,
  // 删除专栏文章
  deleteWriterArticles: `${RESOURCE}/writerArticle/deleteWriterArticles`,
  // 修改专栏文章
  modifyWriterArticle: `${RESOURCE}/writerArticle/modifyWriterArticle`,
  // 新增专栏文章
  addWriterArticle: `${RESOURCE}/writerArticle/addWriterArticle`,
  // 查询文章
  queryArticleDetail: `${RESOURCE}/writerArticle/queryArticleDetail`,
};



