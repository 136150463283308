import { ComponentRef, Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ViewerjsComponent} from './viewerjs.component';

@Injectable()
export class ViewerjsService {
  private viewComponent: ComponentRef<ViewerjsComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  view(List) {
    this.overlayRef = this.overlay.create();
    this.viewComponent = this.overlayRef.attach(new ComponentPortal(ViewerjsComponent));
    this.viewComponent.instance.imageList = List;
  }
}
