import {NzI18nService} from 'ng-zorro-antd';
import {Injectable} from '@angular/core';
import {FormOperate} from './form-opearte.service';
import {FormItem} from './form-config';
import {PageBean} from '../../../shared-module/entity/pageBean';
import {TableConfig} from '../../../shared-module/entity/tableConfig';
import {PageCondition} from '../../entity/queryCondition';
import {CommonUtil} from '../../util/common-util';

@Injectable()
export class BasicConfig {
  // 国际化
  language: any = {};
  // 表单通用配置
  submitLoading = false;
  formStatus: FormOperate;
  formColumn: FormItem[] = [];
  // 表格通用配置
  _dataSet = [];
  // 表格通用分页配置
  pageBean: PageBean = new PageBean(10, 1, 1);
  tableConfig: TableConfig;
  // 图片地址
  uploadUrl = CommonUtil.getUploadUrl();
  // 默认图片地址
  defaultSrc = '/managerPic/u12.png';
  // 表格通用查询条件
  queryConditions = {
    pageCondition: new PageCondition(1, 10),
    filterConditions: [],
    sortCondition: {},
    bizCondition: {}
  };

  constructor(public $nzI18n: NzI18nService) {
    this.language = $nzI18n.getLocale();
    this.queryConditions.pageCondition.pageNum = CommonUtil.getPageIndex().pageIndex;
    this.queryConditions.pageCondition.pageSize = CommonUtil.getPageIndex().pageSize;
  }

  /**
   * 通用表单方法
   * param event
   */
  formInstance(event) {
    this.formStatus = event.instance;
  }
  /**
   * 确定
   */
  formHandleOk() {
  }

  /**
   * 取消
   */
  formHandleCancel() {
    window.history.go(-1);
  }

  /**
   * 恢复默认
   */
  formHandleReset() {
  }

  /**
   * 通用查询
   */
  searchList() {
  }
  setFilter() {
    this.queryConditions.filterConditions = [];
  }
  /**
   * 通用查询表单数据
   */
  searchFromData() {}
  /**
   * 监听页面切换
   * param event
   */
  pageChange(event) {
    this.pageBean.pageIndex = event.pageIndex;
    this.pageBean.pageSize = event.pageSize;
    this.queryConditions.pageCondition = new PageCondition(event.pageIndex, event.pageSize);
    this.searchList();
  }

  /**
   * 手動查詢
   * param event
   */
  handleSearch(event) {
    this.queryConditions.filterConditions = event;
    this.pageBean = new PageBean(this.pageBean.pageSize, 1, this.pageBean.Total);
    this.searchList();
  }
  /**
   * 排序
   * param event
   */
  sortChange(event) {
    this.queryConditions.sortCondition = {
      sortField: event.sortField,
      sortRule: event.sortRule
    };
    this.searchList();
  }
}
