import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-select-input',
  templateUrl: './date-select-input.component.html',
  styleUrls: ['./date-select-input.component.scss']
})
export class DateSelectInputComponent implements OnInit {
  dateValue: number = null;
  dateType: string = 'day';
  constructor() { }

  ngOnInit() {
  }


  dateTypeChange(event) {
    this.dateValue = null;  // 当发生改变时为空
  }


  inputEvents(event) {
    const inputValue = String(event.target.value);
  }
}
