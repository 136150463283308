import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NzI18nService} from 'ng-zorro-antd';
import {EvaluateColumnConfigService} from '../../../business-module/evaluate/evaluate-column-config.service';
import {TableBasic} from '../table/table.basic';
import axios from '../../../core-module/http/http.service';
import {Result} from '../../entity/result';
import {couponApiUrl} from '../../../business-module/e-commerce-management/api-url';
import {QueryCondition} from '../../entity/queryCondition';
import {CommonUtil} from '../../util/common-util';

@Component({
  selector: 'choose-coupon',
  templateUrl: './choose-coupon.component.html',
  styleUrls: ['./choose-coupon.component.scss']
})
export class ChooseCouponComponent extends TableBasic implements OnInit {
  selectData;
  linkData;
  _selectedId = '';
  get selectedId() {
    return this._selectedId;
  }
  @Input() set selectedId(params) {
    // 传入有数据进行回显
    if (params) {
      this._selectedId = params;
    }
  }
  @ViewChild('radioTemp') public radioTemp;  // 单选框组件
  // 关闭页面
  @Output() xcVisibleChange = new EventEmitter<boolean>();
  @Output() selectChange = new EventEmitter();
  constructor(
    public $router: Router,
    public $nzI18n: NzI18nService,
    public $columnConfigService: EvaluateColumnConfigService,
  ) {
    super($nzI18n);
    this.queryCondition = new QueryCondition();
  }

  _xcVisible = false;

  get xcVisible() {
    return this._xcVisible;
  }

  // 组件可视
  @Input()
  set xcVisible(params) {
    if(params) {
      this.refreshData();
    }
    this._xcVisible = params;
    this.xcVisibleChange.emit(this._xcVisible);
  }
  ngOnInit() {
    this.initTableConfig();
  }

  /**
   * 初始化表格配置
   */
  public initTableConfig() {
    this.tableConfig = {
      isDraggable: false,
      isLoading: true,
      showSearchSwitch: true,
      showSizeChanger: true,
      scroll: {x: '600px', y: '340px'},
      noIndex: true,
      showSearchExport: false,
      showPagination: true,
      columnConfig: [
        {
          type: 'render', fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 42,
          renderTemplate: this.radioTemp
        },
        {
          title: '优惠券名称', key: 'couponName', width: 100, isShowSort: true,
          searchable: true, searchConfig: { type: 'input' },
        },
        {
          title: '优惠券类型', key: 'couponType', width: 100, isShowSort: true,
          pipe: 'couponTypePipe',
          searchable: true,
          searchConfig: { type: 'select' , selectInfo: [
              { label: '电子书优惠券', value: 1 },
              { label: '实体书优惠券', value: 2 }
            ]},
        },
        {
          title: '总发行量', key: 'couponCount', width: 100, isShowSort: true,
          searchable: true,
          searchConfig: { type: 'input' },
        },
        {
          title: '剩余数量', key: 'couponInventory', width: 100, isShowSort: true,
          searchable: true,
          searchConfig: { type: 'input' },
        },
        {
          title: '面额', key: 'couponValue', width: 100, isShowSort: true,
          searchable: true,
          searchConfig: { type: 'input' },
        },
        {
          title: '使用门槛', key: 'usingThreshold', width: 100, isShowSort: true,
          searchable: true,
          searchConfig: { type: 'input' },
        },
        ],
      bordered: false,
      notShowPrint: true,
      showSearch: false,
      operation: [],
      leftBottomButtons: [],
      rightTopButtons: []
    };
  }
  refreshData() {
    axios.post(couponApiUrl.queryCouponList, this.queryCondition).then((result: Result) => {
      if (result.code === '0') {
        this.tableConfig.isLoading = false;
        this._dataSet = result.data.data;
        this.pageBean.Total = result['data'].totalCount;
        this.pageBean.pageSize = result['data'].size;
        this.pageBean.pageIndex = result['data'].pageNum;
      }
    });
  }
  /**
   * 单选框点击
   */
  public radioClick(data) {
    this.selectData = data;
  }
  /**
   * 确定按钮发送勾选数据到父组件
   */
  public linkChange() {
    this.linkData = CommonUtil.deepClone(this.selectData);
    this.selectedId = this.linkData.id;
    this.xcVisible = false;
    this.selectChange.emit(this.linkData);
    // this.modelCancel.emit();
  }
  /**
   * 取消按钮关闭modal框
   */
  public linkCancel() {
    if (this.linkData) {
      this.selectedId = this.linkData.id;
    } else {
      this.selectedId = '';
    }
    this.tableConfig.showSearch = false;
    this.xcVisible = false;
  }
}
