import {Injectable} from '@angular/core';
import {RuleUtil} from '../../util/rule-util';
import {ColumnConfig} from '../../entity/tableConfig';
import {NzI18nService} from 'ng-zorro-antd';
import {CommonConfig} from '../../util/common-config';

@Injectable()
export class ResourceColumnConfigService {

  constructor(
    public $ruleUtil: RuleUtil,
    public $nzI18n: NzI18nService
  ) { }

  /**
   * 资源清单列表配置
   */
  private select = {
    type: 'select', fixedStyle: { fixedLeft: true, style: { left: '0px' } }, width: 42
  };
  private author = {
    title: '作者', key: 'author', width: 100,
    searchable: true, searchConfig: { type: 'input' },
    // configurable: true,
    // fixedStyle: { fixedLeft: true, style: { left: '124px' } }
  };
  private serialNumberSelect = {
    type: 'serial-number', width: 70, title: '序号',
    fixedStyle: { fixedRight: true, style: { left: '0px' } }
  };

  private resourceType = {
    title: '资源类型', key: 'resourceTypeName', width: 150,
    searchable: true,
    searchConfig: {
      type: 'select',
      selectType: 'multiple',
      selectInfo: CommonConfig.getResourceTypeList()
    }
  };
  private operateSelect = {
    title: '操作', searchable: true,
    searchConfig: { type: 'operate' }, key: '',
    width: 80, fixedStyle: { fixedRight: true, style: { right: '0px' } }
  };
  /**
   * 获取资源清单列表配置
   */
  getTableResourceListColumnConfig(imgTemp, resourceName): Array<ColumnConfig> {
    const tableConfig: Array<ColumnConfig> = [
      this.select,
      {
        title: '资源封面',
        type: 'render',
        renderTemplate: imgTemp,
        fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 100
      },
      {
        title: '资源名称',
        key: 'resourceName',
        width: 150,
        searchable: true, searchConfig: { type: 'input' },
      },
      this.author,
      this.resourceType,
      // this.operateSelect,
    ];
    return tableConfig;
  }
  /**
   * 获取资源清单列表配置
   */
  getTableResourceListSelectColumnConfig(imgTemp, resourceName): Array<ColumnConfig> {
    const tableConfigSelect: Array<ColumnConfig> = [
      // this.select,
      // this.serialNumber,
      this.serialNumberSelect,
      {
        title: '资源封面',
        type: 'render',
        renderTemplate: imgTemp,
        fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 100
      },
      {
        title: '资源名称', key: 'resourceName',
        searchable: true, searchConfig: { type: 'input' },
      },
      this.operateSelect
    ];
    return tableConfigSelect;
  }
}
