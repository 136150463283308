import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-video-config',
  templateUrl: './video-config.component.html',
  styleUrls: ['./video-config.component.scss']
})
export class VideoConfigComponent implements OnInit {

  @Input() isVisible: boolean = false;
  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter();

  // 视频播放器宽度和高度
  width: number = 300;
  height: number = 300;
  fileName: string = '';
  constructor() { }

  ngOnInit() {
  }

  videoHandler() {
    const videoNode = document.getElementById('uploadVideo');
    videoNode.click();
  }

  /**
   * 视频文件变化
   */
  uploadVideo() {
    const videoNode = document.getElementById('uploadVideo');
    const file = videoNode['files'][0];
    this.fileName = file.name;
    //  todo 文件上传
  }
  handleOk(): void {
    if (this.fileName) {
      this.submit.emit({
        url: 'http://img.ksbbs.com/asset/Mon_1703/eb048d7839442d0.mp4',
        controls: 'controls',
        width: this.width,
        height: this.height
      });
    }
  }

  handleCancel(): void {
    this.cancel.emit();
  }

}
