import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NzI18nService, NzModalComponent, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {audioApiUrl} from 'src/app/business-module/resource/api-url';
import axios from '../../../core-module/http/http.service';
import {Result} from '../../entity/result';
import {TimeConversionPipe} from '../../pipe/timeConversion.pipe';
import {TableBasic} from '../table/table.basic';
import {MessageModalService} from '../info-alert/message-modal.service';
import {QueryCondition} from '../../entity/queryCondition';

/**
 * 上下架记录modal
 */
@Component({
  selector: 'app-shelf-record',
  templateUrl: './shelf-record.component.html',
  styleUrls: ['./shelf-record.component.scss']
})
export class ShelfRecordComponent extends TableBasic implements OnInit, AfterViewInit {
  @Input() set resourceData(params) {
    // 传入有数据进行回显
    if (params) {
      this.queryCondition.bizCondition = {
        resourceId: params.id
      };
      this.refreshData();
    }
  }
  // 关闭页面
  @Output() xcVisibleChange = new EventEmitter<boolean>();
  @ViewChild('shelfModal') shelfModal: NzModalComponent;
  @ViewChild('table') table;
  language;
  constructor(
    public $router: Router,
    public $nzI18n: NzI18nService,
    private modalService: MessageModalService,
    private $timeConversionPipe: TimeConversionPipe,
  ) {
    super($nzI18n);
    this.language = $nzI18n.getLocaleData('resourceManage');
    this.queryCondition = new QueryCondition();
  }

  _xcVisible = false;

  get xcVisible() {
    return this._xcVisible;
  }

  // 组件可视
  @Input()
  set xcVisible(params) {
    this._xcVisible = params;
    this.xcVisibleChange.emit(this._xcVisible);
  }

  ngOnInit() {
    // 初始化页面table配置
    this.initTableConfig();
  }
  ngAfterViewInit() {
  }
  /**
   * 初始化表格配置
   */
  public initTableConfig() {
    this.tableConfig = {
      isDraggable: false,
      showSearchSwitch: true,
      showSizeChanger: true,
      scroll: {x: '600px', y: '340px'},
      noIndex: true,
      showSearchExport: false,
      showPagination: true,
      columnConfig: [
        {
          title: this.language.resourceName, key: 'resourceName', width: 120,
          type: 'input',
          isShowSort: true,
          searchable: true,
          searchConfig: {type: 'input'},
        },
        {
          title: '状态',
          type: 'input', searchable: true, key: 'operation',
          isShowSort: true,
          searchConfig: {type: 'select', selectInfo: [
              { label: this.language.shelf, value: '1' },
              { label: this.language.obtained, value: '0' }
            ]
        },
          width: 105
        },
        {
          title: '日期', key: 'operationDate', width: 150,
          searchKey: 'operationDate',
          isShowSort: true,
          searchable: true,
          searchConfig: {
            type: 'dateRang'
          }
        },
        {
          title: '持续天数', key: 'duration', width: 170,
          searchKey: 'duration',
          isShowSort: true,
          searchable: true,
          searchConfig: {
            type: 'number'
          }
        }, {
          title: '操作人', key: 'insertUser', width: 130,
          searchKey: 'insertUser',
          isShowSort: true,
          searchable: true,
          searchConfig: {
            type: 'input'
          }
        }],
      bordered: false,
      notShowPrint: true,
      showSearch: false,
      operation: [],
      leftBottomButtons: [],
      rightTopButtons: []
    };
  }

  refreshData() {
    axios.post(audioApiUrl.queryResourceOperationList, this.queryCondition).then((result: Result) => {
      if (result.code === '0') {
        this._dataSet = result.data.data.map(item => {
          // 时间转换
          item.operationDate = this.$timeConversionPipe.transform(item.operationDate, 5);
          item.operation = item.operation === 1 ? '上架' : '下架';
          return item;
        });
        this.pageBean.Total = result['data'].totalCount;
        this.pageBean.pageSize = result['data'].size;
        this.pageBean.pageIndex = result['data'].pageNum;
      } else {
        this.modalService.error({
          modalTitle: result.msg, modalContent: ''
        });
      }
    });
  }

  /**
   * 取消按钮关闭modal框
   */
  public linkCancel() {
    this.tableConfig.showSearch = false;
    this.xcVisible = false;
  }
}
