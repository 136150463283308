import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

/**
 * 页面访问权限控制判断
 * 路由守卫参考链接 https://www.cnblogs.com/starof/p/9012193.html
 */
@Injectable()
export class AccessGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // 判断是否可访问当前路由
    const loggedIn: boolean = Math.random() < 0.5;
    if (!loggedIn) {
      // this.router.navigate(['/login']).then();
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
