import table from './table/table.zh_CN';
import form from './form/form.zh_CN';
import common from './common/common.zh_CN';
import breadcrumb from './breadcrumb/breadcrumb.zh_CN';
import notfound from './notfound/notfound.zh_CN';
import classify from './classify-management/classify.zh_CN';
import columnManage from './columnManage/columnManage.zh_CN';
import evaluate from './evaluate/evaluate.zh_CN';
import resourceManage from './resourceManage/resourceManage.zh_CN';
import systemManage from './systemManage/systemManage.zh_CN';

export default {
  table: table,
  form: form,
  common: common,
  breadcrumb: breadcrumb,
  notfound,
  classify,
  columnManage: columnManage,
  evaluate: evaluate,
  resourceManage: resourceManage,
  systemManage: systemManage
};
