import {Injectable} from '@angular/core';
import {ColumnConfig} from '../../shared-module/entity/tableConfig';
import {FormItem} from '../../shared-module/component/form/form-config';
import {RuleUtil} from '../../shared-module/util/rule-util';

@Injectable()
export class EvaluateColumnConfigService {
  constructor(public $ruleUtil: RuleUtil) {
  }

  private relatedBookId = {
    title: '关联书籍ID', key: 'relatedBookId', width: 120, isShowSort: true,
    searchable: true,
    searchConfig: {type: 'input'},
  };
  private focus = {
    title: '侧重点', key: 'focus', width: 120, isShowSort: true,
    searchable: true,
    searchConfig: {type: 'input'},
  };

  /**
   * 获取测评新增表单配置
   */
  getEvaluateDetailFormConfig(difficultTemp): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '年级',
        key: 'grade',
        type: 'select',
        require: false,
        rule: [],
        customRules: [],
        initialValue: '1',
        selectInfo: {
          data: [
            {label: '一年级', value: '1'},
            {label: '二年级', value: '2'},
            {label: '三年级', value: '3'},
            {label: '四年级', value: '4'},
            {label: '五年级', value: '5'},
            {label: '六年级', value: '6'},
            {label: '七年级', value: '7'},
            {label: '八年级', value: '8'},
            {label: '九年级', value: '9'}
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '难度',
        key: 'difficult',
        type: 'custom',
        require: false,
        rule: [],
        col: 24,
        asyncRules: [],
        template: difficultTemp
      },
      {
        label: '测评类型',
        key: 'evaluateType',
        type: 'radio',
        require: false,
        rule: [{required: false}],
        initialValue: '1',
        radioInfo: {
          data: [
            {label: '综合测评', value: '1'},
            {label: '单本测评', value: '0'},
          ],
          label: 'label',
          value: 'value'
        },
        modelChange: (controls, $event, key) => {
          if ($event === '1') {
            controls.controls.relatedBookId.disable();
            controls.controls.focus.enable();
          } else {
            controls.controls.focus.disable();
            controls.controls.relatedBookId.enable();
          }
        },
      },
      {
        label: '侧重点',
        key: 'focus',
        type: 'select',
        require: false,
        rule: [],
        initialValue: '1',
        disabled: false,
        customRules: [],
        selectInfo: {
          data: [
            {label: '阅读能力', value: '1'},
            {label: '专注能力', value: '2'},
            {label: '记忆能力', value: '3'},
            {label: '反省能力', value: '4'}
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '关联书籍ID',
        key: 'relatedBookId',
        type: 'input',
        disabled: true,
        require: false,
        rule: [],
        asyncRules: [],
      }
    ];
    return formColumn;
  }

  /**
   * 获取测评修改表单配置
   */
  getEvaluateDetailEditFormConfig(difficultTemp, pictureTemp): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '年级',
        key: 'grade',
        type: 'select',
        require: false,
        rule: [],
        customRules: [],
        initialValue: '1',
        selectInfo: {
          data: [
            {label: '一年级', value: '1'},
            {label: '二年级', value: '2'},
            {label: '三年级', value: '3'},
            {label: '四年级', value: '4'},
            {label: '五年级', value: '5'},
            {label: '六年级', value: '6'},
            {label: '七年级', value: '7'},
            {label: '八年级', value: '8'},
            {label: '九年级', value: '9'}
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '难度',
        key: 'difficult',
        type: 'custom',
        require: false,
        rule: [],
        col: 24,
        asyncRules: [],
        template: difficultTemp
      },
      {
        label: '测评类型',
        key: 'evaluateType',
        type: 'radio',
        require: false,
        rule: [{required: false}],
        initialValue: '1',
        radioInfo: {
          data: [
            {label: '综合测评', value: '1'},
            {label: '单本测评', value: '0'},
          ],
          label: 'label',
          value: 'value'
        },
        modelChange: (controls, $event, key) => {
          if ($event === '1') {
            controls.controls.relatedBookId.disable();
            controls.controls.focus.enable();
          } else {
            controls.controls.focus.disable();
            controls.controls.relatedBookId.enable();
          }
        },
      },
      {
        label: '侧重点',
        key: 'focus',
        type: 'select',
        require: false,
        rule: [],
        initialValue: '1',
        disabled: false,
        customRules: [],
        selectInfo: {
          data: [
            {label: '阅读能力', value: '1'},
            {label: '专注能力', value: '2'},
            {label: '记忆能力', value: '3'},
            {label: '反省能力', value: '4'}
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '关联书籍ID',
        key: 'relatedBookId',
        type: 'input',
        disabled: true,
        require: false,
        rule: [],
        asyncRules: [],
      },
      {
        label: '题目内容',
        key: 'evaluateName',
        type: 'input',
        require: true,
        rule: [{required: true}, {maxLength: 32},
          this.$ruleUtil.getNameRule()],
        customRules: [this.$ruleUtil.getNameCustomRule()],
        asyncRules: [],
      },
      {
        label: '题目答案',
        key: 'subjectAnswer',
        type: 'select',
        require: true,
        rule: [],
        asyncRules: [],
        initialValue: '1',
        selectInfo: {
          data: [
            {label: 'A', value: '1'},
            {label: 'B', value: '2'},
            {label: 'C', value: '3'},
            {label: 'D', value: '4'}
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '题目类型',
        key: 'subjectType',
        type: 'select',
        require: true,
        rule: [],
        asyncRules: [],
        initialValue: '1',
        selectInfo: {
          data: [
            {label: '单选', value: '1'},
            {label: '多选', value: '2'},
          ],
          label: 'label',
          value: 'value'
        }
      },
      {
        label: '题目图片',
        key: 'picture',
        type: 'custom',
        require: false,
        col: 24,
        asyncRules: [],
        rule: [],
        template: pictureTemp
      }
    ];
    return formColumn;
  }

  /**
   * 左侧的列表
   */
  leftTableDemoColumnConfig(evaluateNameTemp, shelfTemp): Array<ColumnConfig> {
    const tableConfig: Array<ColumnConfig> = [
      {
        type: 'select', fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 42
      },
      {
        title: '测评名称', key: 'evaluationName', width: 160,
        searchable: true,
        type: 'render',
        renderTemplate: evaluateNameTemp,
        searchConfig: {type: 'input'},
      },
      {
        title: '关联导读手册', key: 'resourceName', width: 160,
        searchable: true,
        searchConfig: {type: 'input'},
      },
      {
        title: '题数', key: 'questionNum', width: 50,
        searchable: true,
        searchConfig: {type: 'input'},
      },
      {
        title: '是否上架', key: 'shelfState', width: 70,
        type: 'render',
        renderTemplate: shelfTemp,
      },
      {
        title: '操作', searchable: true,
        searchConfig: {type: 'operate'}, key: '', width: 100, fixedStyle: {fixedRight: true, style: {right: '0px'}}
      }];
    return tableConfig;
  }

  /**
   * 测评管理 左侧列表的新增和编辑
   */
  leftFormConfig(data): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '测评名称',
        key: 'testName',
        type: 'input',
        col: 20,
        require: true,
        rule: [{required: true}]
      }, {
        label: '关联导读手册',
        key: 'guidanceId',
        type: 'select',
        require: true,
        rule: [{required: true}],
        col: 20,
        asyncRules: [],
        selectInfo: {
          data: data,
          //  data: [
          //   {label: '图文列表', value: '1'},
          //   {label: '横向轮播', value: '0'},
          // ],
          label: 'resourceName',
          value: 'id'
        },
      }];
    return formColumn;
  }

  /**
   * 测评 右侧列表
   */
  rightTableDemoColumnConfig(topicTemp): Array<ColumnConfig> {
    const tableConfig: Array<ColumnConfig> = [
      {
        type: 'serial-number', width: 70, title: '序号',
        fixedStyle: {fixedRight: true, style: {left: '0px'}}
      },
      {
        title: '题目', key: 'title',
        type: 'render',
        // configurable: true,
        renderTemplate: topicTemp,
        searchable: true,
        searchConfig: {type: 'input'},
      },
      {
        title: '操作', searchable: true,
        searchConfig: {type: 'operate'}, key: '', width: 72, fixedStyle: {fixedRight: true, style: {right: '0px'}}
      }
    ];
    return tableConfig;
  }

  /**
   * 新增和编辑 题目
   */
  addTopicFormConfig(chooseA, chooseB, chooseC, chooseD): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '题目',
        key: 'stem',
        type: 'textarea',
        col: 28,
        placeholder: '请输入题目',
        resize: true,
        require: true,
        rule: [{required: true}, {maxLength: 200}],
        // rule: [
        //   {required: true},
        //   { maxLength: 200 }],
        // this.$ruleUtil.getRemarkMaxLengthRule()],
        // customRules: [this.$ruleUtil.getNameCustomRule()],
      },
      {
        label: '选项1',
        key: 'chooseA',
        type: 'custom',
        col: 28,
        require: true,
        rule: [{required: true}],
        template: chooseA,
      },
      {
        label: '选项2',
        key: 'chooseB',
        type: 'custom',
        col: 28,
        require: true,
        rule: [{required: true}],
        template: chooseB,
        // rule: [
        //   {required: true},
        //   { maxLength: 50 }]
      },
      {
        label: '选项3',
        key: 'chooseC',
        type: 'custom',
        col: 28,
        rule: [],
        require: false,
        template: chooseC,
        // rule: [{ maxLength: 50 }],
      },
      {
        label: '选项4',
        key: 'chooseD',
        type: 'custom',
        col: 28,
        rule: [],
        require: false,
        template: chooseD,
        // rule: [{ maxLength: 50 }],
      }
    ];
    return formColumn;
  }

  getInformation(titleTemp, shelfTemp, upLoadingTime, evaluationCodeList?): Array<ColumnConfig> {
    const tableConfig: Array<ColumnConfig> = [
      {
        type: 'select', fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 42
      },
      {
        type: 'serial-number', width: 70, title: '序号',
        fixedStyle: {fixedRight: true, style: {left: '42px'}}
      },
      {
        title: '题目', key: 'stem',
        type: 'render',
        renderTemplate: titleTemp,
        searchable: true,
        width: 200,
        searchConfig: {type: 'input'}, isShowSort: true
      },
      {
        title: '测评标签', key: 'evaluationLabelName', width: 200,
        searchKey: 'teq.evaluationLabelCode',
        searchConfig: {type: 'select', selectType: 'multiple', selectInfo: evaluationCodeList}, searchable: true, isShowSort: true
      },
      {
        title: '关联资源', key: 'resourceName', searchable: true, width: 150,
        searchConfig: {type: 'input'}, isShowSort: true
      },
      // {
      //   title: '分类', key: 'feilei', width: 150,
      //   // searchConfig: {type: 'input'}, searchable: true,
      // },
      {
        title: '分级', key: 'gradeNames', searchable: true, width: 150,
        searchKey: 'mg.gradeCode', isShowSort: true,
        searchConfig: {type: 'select', selectType: 'multiple', selectInfo: JSON.parse(localStorage.getItem('gradeList'))},
      },
      {
        title: '上传用户', key: 'uploadUserName', searchable: true, width: 200,
        searchKey: 'userName', isShowSort: true,
        searchConfig: {type: 'input'},
      },
      {
        title: '上传时间', key: 'uploadUserTime', searchable: true, width: 200, type: 'render', renderTemplate: upLoadingTime,
        searchKey: 'teq.insertTime', isShowSort: true,
        searchConfig: {type: 'dateRang'},
      },
      {
        title: '是否通过', type: 'render', key: 'isPass', searchable: true, width: 100, renderTemplate: shelfTemp,
        searchConfig: {
          type: 'select', selectInfo: [
            {label: '是', value: '1'},
            {label: '否', value: '0'}
          ]
        },
      },
      {
        title: '备注', key: 'bak', searchable: true, width: 200,
        searchKey: 'bak', isShowSort: true,
        searchConfig: {type: 'input'},
      },
      {
        title: '操作', searchable: true,
        searchConfig: {type: 'operate'}, key: '',
        width: 100, fixedStyle: {fixedRight: true, style: {right: '0px'}}
      }
    ];
    return tableConfig;
  }

  /**
   * 新增编辑题库
   */
  setInformationForm(stem, resource, grading, optionA, optionB, optionC, optionD, selectData): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '题目',
        key: 'stem',
        type: 'custom',
        col: 40,
        placeholder: '请输入题目',
        resize: true,
        require: true,
        template: stem,
        rule: [{required: true}, {maxLength: 200}],
      },
      {
        label: '关联资源',
        key: 'resource',
        type: 'custom',
        col: 40,
        rule: [],
        template: resource,
      },
      {
        label: '测评标签',
        key: 'evaluationTag',
        type: 'select',
        col: 28,
        require: true,
        rule: [],
        selectInfo: {
          data: selectData,
          label: 'label',
          value: 'value'
        }
        // rule: [
        //   {required: true},
        //   { maxLength: 50 }]
      },
      {
        label: '分级',
        key: 'fenji',
        type: 'custom',
        col: 28,
        rule: [],
        require: true,
        template: grading
      },
      {
        label: '选项1',
        key: 'chooseA',
        type: 'custom',
        template: optionA,
        col: 28,
        // rule: [],
        require: true,
        rule: [{maxLength: 50}],
      },
      {
        label: '选项2',
        key: 'chooseB',
        type: 'custom',
        template: optionB,
        col: 28,
        // rule: [],
        require: true,
        rule: [{maxLength: 50}],
      },
      {
        label: '选项3',
        key: 'chooseC',
        type: 'custom',
        template: optionC,
        col: 28,
        // rule: [],
        require: false,
        rule: [{maxLength: 50}],
      },
      {
        label: '选项4',
        key: 'chooseD',
        type: 'custom',
        template: optionD,
        col: 28,
        // rule: [],
        require: false,
        rule: [{maxLength: 50}],
      }
    ];
    return formColumn;
  }

  /**
   * 获取综合测评表格配置
   */
  getSynthesizeEvaluateTableConfig(obj) {
    const tableConfig: Array<ColumnConfig> = [
      {
        title: '测评名称', key: 'evaluationGrade', width: 300,
        searchable: false,
        renderTemplate: obj.evaluateName,
        type: 'render',
        searchConfig: {type: 'input'},
      },
      {
        title: '参与人数', key: 'participantNumber', width: 300,
        searchable: false,
        searchConfig: {type: 'input'},
      },
      {
        title: '总题数', key: 'subjectNumber', width: 300,
        searchable: false,
        searchConfig: {type: 'input'},
      },
      {
        title: '通过题目数', key: 'passSubject', width: 300,
        searchable: false,
        searchConfig: {type: 'input'},
      },
      {
        title: '操作', searchable: true,
        searchConfig: {type: 'operate'}, key: '', width: 80, fixedStyle: {fixedRight: true, style: {right: '0px'}}
      }];
    return tableConfig;
  }

  /**
   * 测评得分列表
   * param obj
   */
  getSynthesizeEvaluateListTableConfig(obj) {
    const tableConfig: Array<ColumnConfig> = [
      {
        title: '用户名', key: 'nickName', width: 200,
        searchable: false,
        renderTemplate: obj.nickName,
        type: 'render',
        searchConfig: {type: 'input'},
      },
      {
        title: '用户年级', key: 'gradeCode', width: 120,
        searchable: false,
        searchConfig: {type: 'input'},
      },
      {
        title: '答题时间', key: 'insertTime', width: 120,
        searchable: false,
        pipe: 'formatTime',
        pipeParam: 5,
        searchConfig: {type: 'input'},
      },
      {
        title: '得分', key: 'score', width: 120,
        searchable: false,
        searchConfig: {type: 'input'},
      }];
    return tableConfig;
  }

  /**
   * 答卷详情
   * param obj
   */
  getSynthesizeEvaluateDetailTableConfig(obj) {
    const tableConfig: Array<ColumnConfig> = [
      {
        title: '题号', key: 'index', width: 60,
        searchable: false,
        type: 'input',
        searchConfig: {type: 'input'},
      },
      {
        title: '答题情况', key: 'resourceName', width: 200,
        searchable: false,
        renderTemplate: obj.topicInfo,
        type: 'render',
        searchConfig: {type: 'input'},
      }, {
      // 多余 解决宽度问题
        title: '', key: 'q', width: 10,
        searchable: false,
        type: 'input',
        searchConfig: {type: 'input'},
      }];
    return tableConfig;
  }
}
