import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NzI18nService, NzModalRef} from 'ng-zorro-antd';
import {MessageModalService} from '../info-alert/message-modal.service';
import axios from '../../../core-module/http/http.service';
import {rewardActivityApi} from '../../../business-module/activity-management/api-url';
import {Result} from '../../entity/result';

@Component({
  selector: 'app-link-activity',
  templateUrl: './link-activity.component.html',
  styleUrls: ['./link-activity.component.scss']
})
export class LinkActivityComponent implements OnInit {
  // 默认勾选key值数组
  @Input() defaultCheckedKeys;
  // 关闭
  @Output() xcVisibleChange = new EventEmitter<boolean>();
  // 确定按钮返回父节点数组id
  @Output() selectDataChange = new EventEmitter<any>();
  // 树控件
  @ViewChild('permissionTree') permissionTree;
  // 树节点
  nodes = [];
  // 国际化
  language;
  constructor(public modalService: MessageModalService,
              public $nzI18n: NzI18nService) {
    this.language = $nzI18n.getLocaleData('systemManage');
  }
  // 控制显示modal
  _xcVisible = false;

  get xcVisible() {
    return this._xcVisible;
  }

  @Input()
  set xcVisible(params) {
    this._xcVisible = params;
    this.xcVisibleChange.emit(this._xcVisible);
  }
  ngOnInit() {
    // 查询树节点
    this.queryModuleTree();
  }
//   else {
//   inx.checked = true;
// }
  clickNode(event) {
    console.log(event);
    console.log(event.node.key);
    this.nodes.map((item) => {
      console.log(item.children);
      item.children.map((inx) => {
        if (event.checkedKeys.length === 0) {
          inx.disableCheckbox = false;
        } else {
          if (inx.key !== event.node.key) {
            inx.disableCheckbox = true;
          }
        }
      });
    });
    this.nodes = this.nodes.slice();
  }
  /**
   * 确定按钮
   */
  public handleOk() {
    const selectData = [];
    // 父节点和二级三级节点的key值都要传
    this.permissionTree.getCheckedNodeList().map((item) => {
      selectData.push(item);
      if (item.children.length !== 0) {
        item.children.map((items) => {
          selectData.push(items);
        });
      }
    });
    this.selectDataChange.emit(selectData);
    this.handleCancel();
  }

  /**
   * 取消按钮
   */
  public handleCancel() {
    this.xcVisible = false;
  }

  /**
   * 查询树节点
   */
  private queryModuleTree() {
    axios.get(rewardActivityApi.queryModuleTree).then(
      (result: Result) => {
        if (result.code === '0') {
          result.data.map((item) => {
            if (item.moduleChildNodes.length !== 0) {
              item.moduleChildNodes.map((inx) => {
                inx.key = inx.childNodeId;
                inx.title = inx.childNodeName;
                inx.selectable = false;
                inx.isLeaf = true;
              });
            }
            item.title = item.moduleName;
            item.key = item.moduleId;
            item.selectable = false;
            item.isLeaf = false;
            item.expanded = true;
            item.disabled = true;
            item.children = item.moduleChildNodes;
          });
          console.log(result.data);
          this.nodes = result.data;
        } else {
          this.modalService.error({
            modalTitle: result.msg, modalContent: ''
          });
        }
      }
    );
  }
}
