// const queryColumnInfoList = '';

export const COLUMN = `/ksr_manager/column`;
export const BANNER = `/ksr_manager/banner`;
export const FILEUPLOAD = `/ksr_manager/fileupload`;
export const COMMENTMANAGER = `/ksr_manager/commentManager`;
export const OPINIONMANAGER = `/ksr_manager/opinionManager`;
export const MYSTERYACTIVITY = `/ksr_manager/mysteryActivity`;
export const THEMATIC = `/ksr_manager/special`;
export const ACTIVITY = `/ksr_manager/specialTopic`;
export const COLUMNGROUP = `/ksr_manager/columnGroup`;
export const LIVEMANAGE = `/ksr_manager/liveManagement`;
export const SPECIALTOPICAPPLY = `/ksr_manager/specialTopicApply`;
export const KEYWORD = `/ksr_manager/hotWords`;
export const DONATEDBOOK = `/ksr_manager/donatedBook`;

// Banner 页面的相关操作
export const queryBannerInfoList = `${BANNER}/queryBannerInfoList`;
export const modifyBannerDisplay = `${BANNER}/modifyBannerDisplay`;
export const modifyBannerNoDisplay = `${BANNER}/modifyBannerNoDisplay`;
export const deleteBannerInfo = `${BANNER}/deleteBannerInfo`;
export const modifyBannerIndex = `${BANNER}/modifyBannerIndex`;
export const queryBannerInfo = `${BANNER}/queryBannerInfo`;
export const addBannerResource = `${BANNER}/addBannerResource`;
export const modifyBannerResource = `${BANNER}/modifyBannerResource`;
export const deleteBannerResource = `${BANNER}/deleteBannerResource`;
export const addBannerInfo = `${BANNER}/addBannerInfo`;
export const modifyBannerInfo = `${BANNER}/modifyBannerInfo`;
export const getuploadid = `${FILEUPLOAD}/getuploadid`;
export const upload = `${FILEUPLOAD}/upload`;

// 栏目配置 页面的相关操作
const queryColumnInfoList = `${COLUMN}/queryColumnInfoList`;
export const modifyColumnDisplay = `${COLUMN}/modifyColumnDisplay`;
export const modifyColumnNoDisplay = `${COLUMN}/modifyColumnNoDisplay`;
export const addColumnInfo = `${COLUMN}/addColumnInfo`;
export const deleteColumnInfo = `${COLUMN}/deleteColumnInfo`;
export const modifyColumnInfoInit = `${COLUMN}/modifyColumnInfoInit`;

export const modifyColumnInfo = `${COLUMN}/modifyColumnInfo`;
export const modifyColumnIndex = `${COLUMN}/modifyColumnIndex`;
export const relateColumnWriter  = `${COLUMN}/relateColumnWriter `;

// 栏目配置 资源详情
export const queryColumnInfo = `${COLUMN}/queryColumnInfo`;
export const deleteColumnResource = `${COLUMN}/deleteColumnResource`;
export const addColumnResource = `${COLUMN}/addColumnResource`;

// 评论管理
export const queryCommentList = `${COMMENTMANAGER}/queryCommentList`;
export const batchDeleteComment = `${COMMENTMANAGER}/batchDeleteComment`;
export const batchShowComment = `${COMMENTMANAGER}/batchShowComment`;
export const showFlagSwitch = `${COMMENTMANAGER}/showFlagSwitch`;

// 意见反馈
export const queryOpinionList = `${OPINIONMANAGER}/queryOpinionList`;
export const processStateSwitch = `${OPINIONMANAGER}/processStateSwitch`;

// 神秘问题
export const queryMysteryActivityList = `${MYSTERYACTIVITY}/queryMysteryActivitiesByCondition`;
export const addMysteryActivity = `${MYSTERYACTIVITY}/addMysteryActivity`;
export const deleteMysteryActivity = `${MYSTERYACTIVITY}/deleteMysteryActivity`;
export const putOnShelves = `${MYSTERYACTIVITY}/putOnShelves`;
export const pullOffShelves = `${MYSTERYACTIVITY}/pullOffShelves`;
export const queryActivityMembers = `${MYSTERYACTIVITY}/queryActivityMembers`;
export const markActivityMember = `${MYSTERYACTIVITY}/markActivityMember`;
export const modifyMysteryActivityInit = `${MYSTERYACTIVITY}/modifyMysteryActivityInit`;
export const modifyMysteryActivity = `${MYSTERYACTIVITY}/modifyMysteryActivity`;
export const queryMemberActivityResults = `${MYSTERYACTIVITY}/queryMemberActivityResults`;

export const READTEST = 'ksr_manager/readtest';
export const EVALUATION = 'ksr_manager/evaluation';

// 专题管理列表
export const queryThematicList = `${THEMATIC}/querySpecialList`;
export const addThematicInfo = `${THEMATIC}/addSpecialInfo`;
export const deleteSpecialInfo = `${THEMATIC}/deleteSpecialInfo`;
export const modifySpecialInfo = `${THEMATIC}/modifySpecialInfo`;
export const modifySpecialInit = `${THEMATIC}/modifySpecialInit`;
export const getUploadId = `ksr_manager/fileUpload/getUploadId`;
export const relationResource = `${THEMATIC}/relationResource`;
export const queryRelationResourceInfo = `${THEMATIC}/queryRelationResourceId`;
export const querySpecialTopicApplyByCondition = `${SPECIALTOPICAPPLY}/querySpecialTopicApplyByCondition`;
export const modifyJoinStateOn = `${SPECIALTOPICAPPLY}/modifyJoinStateOn`;
export const modifyJoinStateOff = `${SPECIALTOPICAPPLY}/modifyJoinStateOff`;
export const modifySpecialTopicGift = `${SPECIALTOPICAPPLY}/modifySpecialTopicGift`;

// 活动列表
export const queryAllSpecialTopic = `${ACTIVITY}/queryAllSpecialTopic`;
export const updateSpecialTopic = `${ACTIVITY}/updateSpecialTopic`;
export const insertSpecialTopic = `${ACTIVITY}/insertSpecialTopic`;
export const deleteSpecialTopic = `${ACTIVITY}/deleteSpecialTopic`;
export const querySpecialTopic = `${ACTIVITY}/querySpecialTopic`;
export const putOnShelf = `${ACTIVITY}/putOnShelf`;
export const pullOffShelf = `${ACTIVITY}/pullOffShelf`;

export const columnGroupApi = {
  queryColumnGroupStyleList: `${COLUMNGROUP}/queryColumnGroupStyleList`,
  addColumnGroup: `${COLUMNGROUP}/addColumnGroup`,
  modifyColumnGroup: `${COLUMNGROUP}/modifyColumnGroup`,
  queryColumnGroups: `${COLUMNGROUP}/queryColumnGroups`,
  deleteColumnGroups: `${COLUMNGROUP}/deleteColumnGroups`,
  showColumnGroups: `${COLUMNGROUP}/showColumnGroups`,
  hideColumnGroups: `${COLUMNGROUP}/hideColumnGroups`,
  exchangeGroupIndex: `${COLUMNGROUP}/exchangeGroupIndex`,
  queryGroupColumnsByGroupId: `${COLUMNGROUP}/queryGroupColumnsByGroupId`,
  relateGroupColumns: `${COLUMNGROUP}/relateGroupColumns`,
  exchangeGroupColumnIndex: `${COLUMNGROUP}/exchangeGroupColumnIndex`,
  deleteGroupColumns: `${COLUMNGROUP}/deleteGroupColumns`,
};

/**
 * 直播间管理
 */
export const liveManageApi = {
  queryPage: `${LIVEMANAGE}/queryPage`,
  syncData: `${LIVEMANAGE}/syncData`,
};

/**
 * 关键词管理
 */
export const hotKeyword = {
  queryList: `${KEYWORD}/queryList`,
  insert: `${KEYWORD}/insert`,
  delete: `${KEYWORD}/delete`,
  exchange: `${KEYWORD}/exchange`,
};

export const rewardBookApi = {
  queryDonatedBookUsersByCondition: `${DONATEDBOOK}/queryDonatedBookUsersByCondition`,
  updateWinState: `${DONATEDBOOK}/updateWinState`,
  updateConsignmentState: `${DONATEDBOOK}/updateConsignmentState`,
  updatePrizeInfo: `${DONATEDBOOK}/updatePrizeInfo`,
  queryInviteUsersByCondition: `${DONATEDBOOK}/queryInviteUsersByCondition`,
};
export const apiUrl = {
  queryColumnInfoList,
  modifyColumnDisplay,
  modifyColumnNoDisplay,
  addColumnInfo,
  deleteColumnInfo,
  modifyColumnInfoInit,
  modifyColumnInfo,
  modifyColumnIndex,
  queryColumnInfo,
  deleteColumnResource,
  addColumnResource,
  queryBannerInfoList,
  modifyBannerDisplay,
  modifyBannerNoDisplay,
  deleteBannerInfo,
  modifyBannerIndex,
  queryBannerInfo,
  addBannerResource,
  modifyBannerResource,
  deleteBannerResource,
  getuploadid,
  upload,
  addBannerInfo,
  modifyBannerInfo,
  queryCommentList,
  batchDeleteComment,
  batchShowComment,
  showFlagSwitch,
  queryOpinionList,
  processStateSwitch,
  queryMysteryActivityList,
  addMysteryActivity,
  deleteMysteryActivity,
  putOnShelves,
  pullOffShelves,
  queryActivityMembers,
  markActivityMember,
  modifyMysteryActivityInit,
  modifyMysteryActivity,
  queryMemberActivityResults,
  queryThematicList,
  addThematicInfo,
  deleteSpecialInfo,
  modifySpecialInfo,
  modifySpecialInit,
  getUploadId,
  relationResource,
  queryRelationResourceInfo,
  queryAllSpecialTopic,
  updateSpecialTopic,
  insertSpecialTopic,
  deleteSpecialTopic,
  columnGroupApi,
  hotKeyword,
  rewardBookApi
};

