import {PageBean} from '../../entity/pageBean';
import {TableConfig} from '../../entity/tableConfig';
import {QueryCondition} from '../../entity/queryCondition';
import {CommonLanguageInterface} from '../../../../assets/i18n/common/common.language.interface';
import {NzI18nService} from 'ng-zorro-antd';
import {ExportParams} from '../../entity/exportParams';
import {CommonUtil} from '../../util/common-util';

export class TableBasic {
  // 图片地址
  uploadUrl = CommonUtil.getUploadUrl();
  // 默认图片地址
  defaultSrc = '/managerPic/u12.png';
  // 表格通用配置
  _dataSet = [];
  // 表格通用分页配置
  pageBean: PageBean = new PageBean(10, 1, 0);
  tableConfig: TableConfig;
  // 表格通用查询条件
  queryCondition: QueryCondition = new QueryCondition();
  commonLanguage: CommonLanguageInterface;
  exportParams: ExportParams = new ExportParams();
  constructor(
    public $nzI18n: NzI18nService
  ) {
    this.commonLanguage = $nzI18n.getLocaleData('common');
    this.queryCondition.pageCondition.pageNum = CommonUtil.getPageIndex().pageIndex;
    this.queryCondition.pageCondition.pageSize = CommonUtil.getPageIndex().pageSize;
  }

  /**
   * 初始化表格配置
   */
  initTableConfig() {
  }

  /**
   * 数据获取
   */
  refreshData() {}

  /**
   * 监听页面切换
   * param event
   */
  pageChange(event) {
    this.queryCondition.pageCondition.pageNum = event.pageIndex;
    this.queryCondition.pageCondition.pageSize = event.pageSize;
    this.refreshData();
  }
  setFilter() {
    this.queryCondition.filterConditions = [];
  }
  /**
   * 手动查询
   * param event
   */
  handleSearch(event) {
    this.queryCondition.filterConditions = event;
    this.setPageCondition(event);
    this.refreshData();
  }

  /**
   * 设置查询条件
   */
  setPageCondition(event) {
    this.pageBean = new PageBean(this.pageBean.pageSize, 1, 0);
    this.queryCondition.pageCondition.pageNum = 1;
    // this.queryCondition.pageCondition.pageSize = event.pageSize;
  }

  /**
   * 删除等操作后查询条件修改
   */
  resetPageCondition() {
    this.pageBean.pageIndex = 1;
    this.queryCondition.pageCondition.pageNum = 1;
  }

  /**
   * 排序
   * param event
   */
  sortChange(event) {
    this.queryCondition.sortCondition.sortField = event.sortField;
    this.queryCondition.sortCondition.sortRule = event.sortRule;
    this.refreshData();
  }

  /**
   * 导出
   * param event
   */
  handleExport(event) {
  }

  /**
   * 打印
   * param event
   */
  handlePrint(event) {
  }

  dealUpDown() {
    this._dataSet = this._dataSet.map((item, index) => {
      if (index === 0 || index === this._dataSet.length - 1) {
        if (index === 0) {
          item.up = 'disabled';
          item.down = true;
        } else {
          item.up = true;
          item.down = 'disabled';
        }
        if (this._dataSet.length === 1) {
          item.up = 'disabled';
          item.down = 'disabled';
        }
      } else {
        item.up = true;
        item.down = true;
      }
      return item;
    });
  }
}
