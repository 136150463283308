import { Injectable } from '@angular/core';
import * as OSS from 'ali-oss';
import * as uuidv4 from 'uuid/v4';
import axios from '../../core-module/http/http.service';
import {SessionUtil} from './session-util';
import {CommonAesService} from './common-aes.service';
import {MessageModalService} from '../component/info-alert/message-modal.service';
@Injectable()
export class UploadService {
  ossConfig = SessionUtil.getOssConfig();
  ossClient: any;
  uploadUrl = '';
  /**
  1 Banner .jpg/.jpeg/.png 2000 upload/banner/
  2 资源（电子书） .epub 100000 upload/resource/epub/
  3 资源（有声书） .mp3 80000 upload/resource/audio/
  4 资源（视频课程） .zip 200000 upload/resource/video/
  5 资源封面 .jpg/.jpeg/.png 2000 upload/resource/img/
  6 阅读检测 .jpg/.jpeg/.png 2000 upload/readingtest/
  7 分类管理 .ico 300 upload/category/
  8 栏目管理 .jpg/.jpeg/.png 2000 upload/column/
  9 用户头像 .jpg/.jpeg/.png 1000 upload/mobile/avatar/
  10 导读手册 .mp3 30000 upload/resource/guide/
  11 测评 .jpg/.jpeg/.png 2000 upload/evaluation/
  12 专题 .jpg/.jpeg/.png 2000 upload/special/
  13 有声书解析文件 .* 1000000 analysis/
  14 临时目录 .* 1000000 temporary/
   15 资源（绘本）-听书 .mp3 80000 upload/resource/picbook/
   16 资源（绘本）-讲书 .zip 200000 upload/resource/picbook/
   17 资源（绘本）-解书 .zip 200000 upload/resource/picbook/
   18 资源（绘本）-图片内容 .jpg/.jpeg/.png 2000 upload/resource/picbook/
   19 资源（绘本）-封面 .jpg/.jpeg/.png 2000 upload/resource/
   20 作家专栏-作家管理 .jpg/.jpeg/.png 2000 upload/writercolumn/writer
   21 作家专栏-专栏文章 .jpg/.jpeg/.png 2000 upload/writercolumn/article
   22 活动管理-专题活动 .jpg/.jpeg/.png 2000 upload/specialtopic
   23 高清资源封面（电子书） .jpg/.jpeg/.png 2000 upload/resource/hdimg/epub/
   24 高清资源封面（绘本） .jpg/.jpeg/.png 2000 upload/resource/hdimg/picbook/
   **/
  uploadConfigList = [{
    type: 1,
    url: 'upload/banner/'
  }, {
    type: 2,
    url: 'upload/resource/epub/'
  }, {
    type: 3,
    url: 'upload/resource/audio/'
  }, {
    type: 4,
    url: 'upload/resource/video/'
  }, {
    type: 5,
    url: 'upload/resource/img/'
  }, {
    type: 6,
    url: 'upload/readingtest/'
  }, {
    type: 7,
    url: 'upload/category/'
  }, {
    type: 8,
    url: 'upload/column/'
  }, {
    type: 9,
    url: 'upload/mobile/avatar/'
  }, {
    type: 10,
    url: 'upload/resource/guide/'
  }, {
    type: 11,
    url: 'upload/evaluation/'
  }, {
    type: 12,
    url: 'upload/special/'
  }, {
    type: 13,
    url: 'analysis/'
  }, {
    type: 14,
    url: 'temporary/'
  }, {
    type: 15,
    url: 'upload/resource/picbook/'
  }, {
    type: 16,
    url: 'upload/resource/picbook/'
  }, {
    type: 17,
    url: 'upload/resource/picbook/'
  }, {
    type: 18,
    url: 'upload/resource/picbook/'
  }, {
    type: 19,
    url: 'upload/resource/picbook/cover/'
  }, {
      type: 20,
      url: 'upload/writercolumn/writer/'
  }, {
      type: 21,
      url: 'upload/writercolumn/article/'
    }, {
    type: 22,
    url: 'upload/specialtopic/'
  }, {
    type: 23,
    url: 'upload/resource/hdimg/epub/'
  }, {
    type: 24,
    url: 'upload/resource/hdimg/picbook/'
  }
  ];
  constructor(private $modalService: MessageModalService) {
    this.ossClient = new OSS({
      accessKeyId: CommonAesService.decrypt(this.ossConfig.accessKeyId),
      accessKeySecret: CommonAesService.decrypt(this.ossConfig.accessKeySecret),
      bucket: CommonAesService.decrypt(this.ossConfig.bucket),
      region: CommonAesService.decrypt(this.ossConfig.region),
      useFetch: false
    });
    this.uploadUrl = `https://${CommonAesService.decrypt(this.ossConfig.bucket)}.${CommonAesService.decrypt(this.ossConfig.region)}.aliyuncs.com/`;
  }

  // 上传接口
  upload(type, file) {
    // 根据上传类型获取上传路径
    const uploadConfig =  this.uploadConfigList.filter(item => item.type === type);
    const names = file.name.split('.');
    // uuid生成文件名称
    const fileName = `${uploadConfig[0].url}${uuidv4().replace(/-/g, '')}.${names[names.length - 1]}`;
    return new Promise((resolve, rej) => {
      this.ossClient.put(fileName, file).then((result) => {
        resolve(fileName);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  /**
   * 大文件上传
   */
  bigFileUpload(type, file, callback?) {
    // 根据上传类型获取上传路径
    const uploadConfig =  this.uploadConfigList.filter(item => item.type === type);
    const names = file.name.split('.');
    // uuid生成文件名称
    const fileName = `${uploadConfig[0].url}${uuidv4().replace(/-/g, '')}.${names[names.length - 1]}`;
    return new Promise((resolve, rej) => {
      this.ossClient.multipartUpload(fileName, file, {
        progress: function(p) {
          callback(p);
        },
        // 分片大小
        partSize: 600 * 1024,
        parallel: 2
      }).then(() => {
        resolve(fileName);
      }).catch((err) => {
        rej(err);
      });
    });
  }
  /**
   * MP4文件转m3u8
   * param url（上传到oss时的路径）
   */
  mp4ToM3u8(url) {
    return axios.get(`/ksr_manager/videoCourse/filePath?filePath=${url}`);
  }

  /**
   * 选择文件
   */
  selectFile(type?: Array<string>) {
    return new Promise((resolve) => {
      const inputNode = document.createElement('input');
      inputNode.setAttribute('type', 'file');
      document.body.appendChild(inputNode);
      inputNode.click();
      inputNode.onchange = () => {
        const file = inputNode['files'][0];
        const fileName = file.name;
        document.body.removeChild(inputNode);
        let reg = /(.*)$/i;
        if (type) {
          reg = new RegExp(`(${type.join('|')})$`, 'i');
        }
        if (reg.test(fileName)) {
            resolve(file);
        } else {
          this.$modalService.warn({
            modalTitle: `文件格式不正确，仅支持${type.join()}`
          });
        }
      };
    });
  }
}
