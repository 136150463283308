import {ComponentRef, Injectable} from '@angular/core';
import {SelectBookSingleComponent} from './select-book-single.component';
import {OverlayRef, Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable()
export class SelectBookSingleService {

  private selectBookSingle: ComponentRef<SelectBookSingleComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  select(filters, selected) {
    return new Promise((resolve) => {
      this.overlayRef = this.overlay.create();
      this.selectBookSingle = this.overlayRef.attach(new ComponentPortal(SelectBookSingleComponent));
      this.selectBookSingle.instance.showSelectModel(filters, selected);
      this.selectBookSingle.instance.selectChange.subscribe((data) => {
        resolve(data);
      });
      this.selectBookSingle.instance.modelCancel.subscribe(() => {
        this.selectBookSingle.destroy();
        resolve(null);
      });
    });
  }
}
