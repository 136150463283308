import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BasicConfig} from '../form/basic-config';
import {Router} from '@angular/router';
import {NzI18nService} from 'ng-zorro-antd';
import {EvaluateColumnConfigService} from '../../../business-module/evaluate/evaluate-column-config.service';
import {MessageModalService} from '../info-alert/message-modal.service';
import axios from '../../../core-module/http/http.service';
import {Result} from '../../entity/result';
import {PageCondition} from '../../entity/queryCondition';
import {PageBean} from '../../entity/pageBean';
import {CommonConfig} from '../../util/common-config';
import {CommonUtil} from '../../util/common-util';

/**
 * 关联导读手册模块
 */
@Component({
  selector: 'app-link-guide-book',
  templateUrl: './link-guide-book.component.html',
  styleUrls: ['./link-guide-book.component.scss']
})
export class LinkGuideBookComponent extends BasicConfig implements OnInit {
  @ViewChild('imgTemp') public imgTemp;  // 封面图片组件
  @ViewChild('resourceLinkName') public resourceLinkName;  // 资源名称组件
  @ViewChild('radioTemp') public radioTemp;  // 单选框组件
  // 关联资源回显
  @Input() set linkGuideBook(params) {
    // 传入有数据进行回显
    if (params) {
      this.linkData = this._dataSet.filter(item => item.id === params.resourceId);
      this.selectedId = params.resourceId;
    }
  }


  // 关闭页面
  @Output() xcVisibleChange = new EventEmitter<boolean>();
  // 勾选数据传入到父组件
  @Output() dataChange = new EventEmitter();
  // model框标题
  linkTitle;
  // 关联的数据，要发送给父组件
  linkData;
  // 关联数据id，用于单选框回显
  selectedId;
  selectData;

  constructor(
    public $router: Router,
    public $nzI18n: NzI18nService,
    public $columnConfigService: EvaluateColumnConfigService,
    private modalService: MessageModalService,
  ) {
    super($nzI18n);
    this.language = $nzI18n.getLocaleData('resourceManage');
  }

  _xcVisible = false;
  _helpQueryCondition;
  get helpQueryCondition() {
    return this._helpQueryCondition;
  }

  // 组件可视
  @Input()
  set helpQueryCondition(params) {
    this._helpQueryCondition = params;
    this.initTableConfig();
    if (this._helpQueryCondition) {
      this.queryConditions.filterConditions.push(this._helpQueryCondition);
      this.searchList();
    } else {
      this.queryConditions.filterConditions = [
        {
          'filterField': 'shelfState',
          'operator': 'eq',
          'filterValue': 1
        }
      ];
      this.searchList();
    }
  }

  get xcVisible() {
    return this._xcVisible;
  }

  // 组件可视
  @Input()
  set xcVisible(params) {
    this._xcVisible = params;
    this.xcVisibleChange.emit(this._xcVisible);
  }

  ngOnInit() {
    // 初始化页面table配置

    this.linkTitle = this.language.linkResource;
    this.queryConditions.pageCondition = new PageCondition(1, 20);
    this.queryConditions.filterConditions = [
      {
        'filterField': 'shelfState',
        'operator': 'eq',
        'filterValue': 1
      }
    ];
    if (this._helpQueryCondition) {
      this.tableConfig.columnConfig.map(item => {
        if (item.searchKey === 'resourceType') {
          item.searchable = false;
        }
      });
      this.queryConditions.filterConditions.push(this._helpQueryCondition, {
        'filterField': 'gradeList',
        'operator': 'eq',
        'filterValue': [1, 2, 3, 4, 5, 6, 7, 8, 9]
      });
    }
    // 数据放入
    // this.searchList();
  }

  /**
   * 初始化表格配置
   */
  public initTableConfig() {
    this.tableConfig = {
      isDraggable: false,
      isLoading: true,
      showSearchSwitch: true,
      showSizeChanger: true,
      scroll: {x: '600px', y: '340px'},
      noIndex: true,
      showSearchExport: false,
      showPagination: true,
      columnConfig: [
        {
          type: 'render', fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 42,
          renderTemplate: this.radioTemp
        },
        {
          title: this.language.resourceCover,
          type: 'render',
          renderTemplate: this.imgTemp,
          width: 105
        },
        {
          title: this.language.resourceName, key: 'resourceName', width: 120,
          type: 'input',
          searchable: true,
          searchConfig: {type: 'input'},
        },
        {
          title: this.language.resourceCategory, key: 'resourceTypeName', width: 170,
          searchKey: 'resourceType',
          searchable: true,
          searchConfig: {
            type: 'select',
            selectType: 'multiple',
            selectInfo: CommonConfig.getResourceTypeList()
          }
        }],
      bordered: false,
      notShowPrint: true,
      showSearch: false,
      operation: [],
      leftBottomButtons: [],
      rightTopButtons: []
    };
    if (this._helpQueryCondition && this._helpQueryCondition.filterField === 'resourceType'
      && this._helpQueryCondition.filterValue.every(item => item === 5)) {
      let dataIndex;
      this.tableConfig.columnConfig.forEach((item, index) => {
        if (item.key === 'gradeNameList') {
          dataIndex = index;
        }
      });
      console.log(dataIndex);
    } else {

      this.tableConfig.columnConfig.push({
        title: this.language.resourceGrade, key: 'gradeNameList', width: 170,
        searchKey: 'gradeList',
        searchable: true,
        searchConfig: {
          type: 'select',
          selectType: 'multiple',
          selectInfo: CommonConfig.getGradeList()
        }
      });
    }
  }

  /**
   * 确定按钮发送勾选数据到父组件
   */
  public linkChange() {
    this.linkData = CommonUtil.deepClone(this.selectData);
    this.selectedId = this.linkData.id;
    this.dataChange.emit(this.linkData);
    this.xcVisible = false;
  }

  /**
   * 监听页面切换
   * param event
   */
  pageChange(event) {
    this.queryConditions.pageCondition = event;
    this.queryConditions.pageCondition = new PageCondition(event.pageIndex, event.pageSize);
    this.pageBean.pageIndex = event.pageIndex;
    this.pageBean.pageSize = event.pageSize;
    this.queryConditions.filterConditions = [
      {
        'filterField': 'shelfState',
        'operator': 'eq',
        'filterValue': 1
      }
    ];
    if (this._helpQueryCondition) {
      this.queryConditions.filterConditions.push(this._helpQueryCondition);
      if (event.length === 0) {
        this.queryConditions.filterConditions.push({
          'filterField': 'gradeList',
          'operator': 'eq',
          'filterValue': [1, 2, 3, 4, 5, 6, 7, 8, 9]
        });
      }
    }
    this.searchList();
  }

  /**
   * 手動查詢
   * param event
   */
  handleSearch(event) {
    this.queryConditions.pageCondition = new PageCondition(1, this.pageBean.pageSize);
    this.queryConditions.filterConditions = [
      {
        'filterField': 'shelfState',
        'operator': 'eq',
        'filterValue': 1
      }
    ];
    if (this._helpQueryCondition) {
      this.queryConditions.filterConditions.push(this._helpQueryCondition);
      if (event.length === 0  &&  this._helpQueryCondition.filterValue.every(item => item !== 5)) {
        this.queryConditions.filterConditions.push({
          'filterField': 'gradeList',
          'operator': 'eq',
          'filterValue': [1, 2, 3, 4, 5, 6, 7, 8, 9]
        });
      }
    }
    this.queryConditions.filterConditions = this.queryConditions.filterConditions.concat(event);
    this.pageBean = new PageBean(this.pageBean.pageSize, 1, this.pageBean.Total);
    this.searchList();
  }

  searchList() {
    axios.post('/ksr_manager/resourceManager/queryResourceInfoList', this.queryConditions).then((result: Result) => {
      if (result.code === '0') {
        this.tableConfig.isLoading = false;
        result.data.data.map((item) => {
          item.gradeNameList = item.gradeList.map(inx => inx.gradeName) || ['--'];
        });
        this._dataSet = result.data.data;
        this.pageBean.Total = result['data'].totalCount;
        this.pageBean.pageSize = result['data'].size;
        this.pageBean.pageIndex = result['data'].pageNum;
      }
    });
  }

  /**
   * 取消按钮关闭modal框
   */
  public linkCancel() {
    if (this.linkData) {
      this.selectedId = this.linkData.id;
    } else {
      this.selectedId = '';
    }
    this.xcVisible = false;
  }

  /**
   * 单选框点击
   */
  public radioClick(data) {
    this.selectData = data;
  }
}
