import { ComponentRef, Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import { ResourceSelectComponent } from '../../component/resource-select/resource-select.component';

@Injectable()
export class ResourceSelectService {
  private resourceSelectService: ComponentRef<ResourceSelectComponent> | any;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}
  start(type: string, listData: any, screenType?: any ) {
    this.overlayRef = this.overlay.create();
    this.resourceSelectService = this.overlayRef.attach(new ComponentPortal(ResourceSelectComponent));
    this.resourceSelectService.instance.resourceInto(type, listData, screenType);
    this.resourceSelectService.instance.closeSelect.subscribe( () => {
      this.resourceSelectService.destroy();
    });
  }

  /**
   * 关联资源
   */
  selectResource(type, selectedList, filter?) {
    return new Promise((resolve, reject) => {
      this.overlayRef = this.overlay.create();
      this.resourceSelectService = this.overlayRef.attach(new ComponentPortal(ResourceSelectComponent));
      this.resourceSelectService.instance.resourceInto(type, selectedList, filter);
      this.resourceSelectService.instance.resourceListChange.subscribe( (resourceList) => {
        resolve(resourceList);
        this.resourceSelectService.destroy();
      });
    });
  }

}
