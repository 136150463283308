import { Component, OnInit, Output, EventEmitter , Input } from '@angular/core';
import axios from '../../../core-module/http/http.service';
import {ModalService} from '../../service/modal/modal.service';
@Component({
  selector: 'app-uploading',
  templateUrl: './uploading.component.html',
  styleUrls: ['./uploading.component.scss']
})
export class UploadingComponent implements OnInit {
  display = {
    popUp: false
  };
  uploadingTitle;
  @Input()
  set titleText( titleText: string) {
    if ( titleText ) {
       this.display.popUp = true;
       this.uploadingTitle = titleText;
    }
  }
  @Output()
  uploadData = new EventEmitter();
  data = [];
  uploadMessage: string;
  progressData: number;
  constructor(
    public $message: ModalService,
  ) { }

  save() {
    this.uploadData.emit(this.data);
    for ( let i = 0; i < this.data.length; i++ ) {
      const sendData = new FormData();
      sendData.append('file', this.data[i]);
      const that = this;
      axios.post('/file/fileupload', sendData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // 处理上传进度事件
        onUploadProgress: function (progressEvent) {
          that.data[i].progressData = progressEvent.loaded / progressEvent.total * 100 | 0 ;
          // const complete = ( progressEvent.loaded / progressEvent.total * 100 | 0 ) + '%';
          // that.uploadMessage = '上传 ' + complete;
          // return  progressEvent.loaded / progressEvent.total * 100 | 0 ;
        },
        // 处理下载进度事件
        onDownloadProgress: function (progressEvent) {},
      }).then(result => {
        // if ( result['code'] === 0 ) {
        //   this.$message.info(that.uploadMessage);
        // }
      });
    }
  }
  close(index) {
    // 删除指定项
    // for ( let i = 0; i < this.data.length; i++ ) {
    //   // this.data.splice(index, 1);
    // }
  }
  uploadChange(event) {
    this.data = event.target.files;
  }
  clickUploading() {
    const uploadingId: any = document.getElementById('uploadingId');
    uploadingId.click();
  }
  ngOnInit() {
  }

}
