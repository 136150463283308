import {NzI18nService} from 'ng-zorro-antd';
import {Injectable} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {CommonLanguageInterface} from '../../../assets/i18n/common/common.language.interface';
import {debounceTime, distinctUntilChanged, first, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable()
export class RuleUtil {
  language: CommonLanguageInterface;

  constructor(private $i18n: NzI18nService) {
    this.language = this.$i18n.getLocaleData('common');
  }

  static getNameMinLengthRule() {
    return {minLength: 1};
  }

  static getNameMaxLengthRule() {
    return {maxLength: 32};
  }

  static getNamePatternRule(msg = null) {
    if (msg) {
      return {pattern: '^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]+$', msg};
    } else {
      return {pattern: '^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]+$'};
    }
  }

  getAccountRule() {
    return {
      pattern: '^(?!_)[a-zA-Z0-9]+$',
      msg: '账号名不规则'
    };
  }
  /**
   * 获取名称检验规则
   * returns {{pattern: string; msg: any}}
   */
  getNameRule() {
    return {
      pattern: '^[\\s\\da-zA-Z\u4e00-\u9fa5`\\-=\\[\\]\\\\;\',./~!@#$%^&*\\(\\)_+{}|:"<>?·【】、；\'、‘’，。、！￥……（）——+｛｝：“”《》？]+$',
      msg: this.language.nameRuleMsg
    };
  }

  /**
   * 获取名称自定义校验规则
   * returns {{code: string; msg: any; validator: (control: AbstractControl) => {[p: string]: boolean}}}
   */
  getNameCustomRule() {
    return {
      code: 'notEmpty', msg: this.language.notEmpty, validator: (control: AbstractControl): { [key: string]: boolean } => {
        if (/^\s+$/.test(control.value)) {
          return {notEmpty: true};
        } else {
          return null;
        }
      }
    };
  }

  /**
   * 获取名称异步校验规则
   * param {() => Observable<Object>} httpMethod 请求函数
   * param {(res) => boolean} nameNotExist 验证名称是否存在 不存在返回true
   * returns {{asyncRule: (control: FormControl) => Observable<any>; asyncCode: string; msg: string}}
   */
  getNameAsyncRule(httpMethod: (value) => Observable<Object>, nameNotExist: (res) => boolean) {
    return {
      asyncRule: (control: FormControl) => {
        return control.valueChanges.pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          mergeMap(() => httpMethod(control.value)),
          mergeMap(res => {
            if (nameNotExist(res)) {
              return of(null);
            } else {
              return of({error: true, duplicated: true});
            }
          }),
          first()
        );
      },
      asyncCode: 'duplicated', msg: this.language.nameExists
    };
  }

  /**
   * 备注类最大长度
   * returns {{maxLength: number}}
   */
  getRemarkMaxLengthRule() {
    return {maxLength: 150};
  }

  /**
   * 获取数字检验规则
   */
  getNumberRule(param) {
    if (param === '1') {
      return {
        code: 'num',
        msg: '最少包含1个数字！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^.*(?=.*\d).*$/.test(control.value)) {
            return {num: true};
          } else {
            return null;
          }
        }
      };
    } else {
      return {
        code: 'num',
        msg: '',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          return null;
        }
      };
    }
  }

  /**
   * 获取小写字母检验规则
   */
  getLowerCaseRule(param) {
    if (param === '1') {
      return {
        code: 'lowercase',
        msg: '最少包含1个小写字母！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^.*(?=.*[a-z]).*$/.test(control.value)) {
            return {lowercase: true};
          } else {
            return null;
          }
        }
      };
    } else {
      return {
        code: 'lowercase',
        msg: '',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          return null;
        }
      };
    }
  }


  /**
   * 获取大写字母检验规则
   */
  getUpperCaseRule(param) {
    if (param === '1') {
      return {
        code: 'uppercase',
        msg: '最少包含1个大写字母！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^.*(?=.*[A-Z]).*$/.test(control.value)) {
            return {uppercase: true};
          } else {
            return null;
          }
        }
      };
    } else {
      return {
        code: 'uppercase',
        msg: '',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          return null;
        }
      };
    }
  }

  /**
   * 获取特殊字符检验规则
   */
  getSpecialCharacterRule(param) {
    if (param === '1') {
      return {
        code: 'Character',
        msg: '最少包含1个特殊字符！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^.*(?=.*[!@#$%.\-_&*<>?\(\)\[\]{}\\|;:]).*$/.test(control.value)) {
            return {Character: true};
          } else {
            return null;
          }
        }
      };
    } else {
      return {
        code: 'Character',
        msg: '',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          return null;
        }
      };
    }
  }

  /**
   * 获取邮箱检验规则
   */
  getMailRule() {
    return {
      pattern: '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\\.[a-zA-Z0-9_-]+)+$',
      msg: '邮箱格式不正确！'
    };
  }
  getTelRule() {
    return {
      pattern: '^1[3456789]\\d{9}$',
      msg: '手机号码不正确！'
    };
  }
  // number框小数点后两位
  getFlorRule() {
    return {
      pattern: /^[0-9]\d{0,7}(\.\d{1,2})?$/,
      msg: '数值不正确！'
    };
  }
  getNumRule() {
    return {
      pattern: '^[0-9]*[1-9][0-9]*$',
      msg: '请输入数字！'
    };
  }
  getNumCustomRule() {
    return {
      code: 'notNum', msg: this.language.notNum, validator: (control: AbstractControl): { [key: string]: boolean } => {
        if (/^[0-9]*[1-9][0-9]*$/.test(control.value)) {
          return {notNum: true};
        } else {
          return null;
        }
      }
    };
  }
  /**
   * 获取转储条数效验规则
   */
  getDumpNumRule(param) {
    if (param === '1') {
      return {
        code: 'dumpQuantityThreshold',
        msg: '转储数量阈值10000-1000000！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^([1-9]\d{4,5}|[1][0]{6})$/.test(control.value)) {
            return {dumpQuantityThreshold: true};
          } else {
            return null;
          }
        }
      };
    } else {
      return {
        code: 'turnOutNumber',
        msg: '转储条数为10000-1000000！',
        validator: (control: AbstractControl): { [key: string]: boolean } => {
          if (!/^([1-9]\d{4,5}|[1][0]{6})$/.test(control.value)) {
            return {turnOutNumber: true};
          } else {
            return null;
          }
        }
      };
    }
  }

  /**
   * 获取转储时间间隔效验规则
   */
  getDumpMonthRule() {
    return {
      code: 'dumpInterval',
      msg: '转储时间间隔为1-24',
      validator: (control: AbstractControl): { [key: string]: boolean } => {
        if (!/^([1-9]|[1][0-9]|[2][0-4])$/.test(control.value)) {
          return {dumpInterval: true};
        } else {
          return null;
        }
      }
    };
  }

}
