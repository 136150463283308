export default {
  addClassify: 'add classify',
  updateClassify: 'update Classify',
  resourceClassify: 'resource classify',
  add: 'add',
  delete: 'delete',
  okTest: 'ok test',
  cancel: 'cancel',
  clickUploadImage: 'click Upload Image',
  clickUpdateImage: 'click Update Image',
  areYouSureToDeleteThisResourceCategory: 'Are You Sure To Delete This Resource Category',
  classificationDeletedWillNotBeRestoredPleaseBeCarefulOperation: 'classification Deleted Will Not Be Restored Please Be Careful Operation',
  classifyName: 'classify Name',
  icon: 'icon',
  belongsToTheCategory: 'belongsToTheCategory',
};
