import {ComponentRef, Injectable} from '@angular/core';
import {ViewPictureComponent} from '../../component/view-picture/view-picture.component';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable()
export class ViewPictureService {

  private viewPicture: ComponentRef<ViewPictureComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  view(fileName, src) {
    this.overlayRef = this.overlay.create();
    this.viewPicture = this.overlayRef.attach(new ComponentPortal(ViewPictureComponent));
    this.viewPicture.instance.qrCodeVisible = true;
    this.viewPicture.instance.fileName = fileName;
    this.viewPicture.instance.qrCodeSrc = src;
    this.viewPicture.instance.close = () => {
      this.viewPicture.instance.qrCodeVisible = false;
      this.viewPicture.destroy();
    };
  }
}
