import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {MessageModalService} from '../info-alert/message-modal.service';
import {UploadService} from '../../util/upload.service';
import * as uuidv4 from 'uuid/v4';
@Component({
  selector: 'app-e-book-upload',
  templateUrl: './e-book-upload.component.html',
  styleUrls: ['./e-book-upload.component.scss']
})
export class EBookUploadComponent implements OnInit, OnChanges {
  // 按钮东动画
  submitLoading = false;
  // 文件上传百分比
  percent = 0;
  // 上传输入框node
  inputNode: any;
  id = uuidv4();
  // 上传文件格式
  @Input() reg: RegExp;
  @Input() uploadMsg: string = '文件格式不正确';
  // 上传文件路径
  @Input() uploadPath: number;
  @Input() fileInfo = {
    fileName: '',
    filePath: '',
    fileSize: 0
  };
  @Output() onchange = new EventEmitter();
  @Output() onStartUpload = new EventEmitter();
  constructor(private $modalService: MessageModalService,
              private $uploadService: UploadService) { }

  ngOnInit() {
    if (!this.uploadPath) {
      this.$modalService.warn({
        modalTitle: '请设置文件上传路径！'
      });
    }
    if (!this.reg) {
      this.$modalService.warn({
        modalTitle: '请设置文件上传格式！'
      });
    }
  }

  ngOnChanges(): void {
    if (this.fileInfo && this.fileInfo.fileName) {
      this.percent = 100;
    }
  }

  /**
   * 点击上传按钮
   */
  public upload() {
    this.inputNode = document.getElementById(this.id);
    this.inputNode.click();
  }

  /**
   * 监听文件上传
   */
  public fileChange() {
    this.onStartUpload.emit();
    setTimeout(() => {
      const fileNode = this.inputNode['files'][0];
    if (this.reg.test(fileNode.name)) {
      this.fileInfo = {
        fileName: '',
        filePath: '',
        fileSize: 0
      };
      this.fileInfo.fileName = (fileNode.name.substring(0, fileNode.name.lastIndexOf('.')));
      this.fileInfo.fileSize = fileNode.size;
      this.$uploadService.bigFileUpload(this.uploadPath, fileNode, (percent) => {
        this.percent = parseInt(percent * 100 + '', 0);
      }).then((url: string) => {
        this.fileInfo.filePath = url;
        this.onchange.emit(this.fileInfo);
      });
    } else {
      this.inputNode['value'] = '';
      this.$modalService.error({
        modalTitle: this.uploadMsg
      });
    }
    }, 100);
  }
}
