import {Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalService} from '../../service/modal/modal.service';
import axios from '../../../core-module/http/http.service';
import {audioApiUrl} from '../../../business-module/resource/api-url';
import {Result} from '../../entity/result';
import {UploadService} from '../../util/upload.service';

@Component({
  selector: 'app-uploading-img',
  templateUrl: './uploading-img.component.html',
  styleUrls: ['./uploading-img.component.scss']
})

export class UploadingImgComponent implements OnInit {
  uploadingImgText: string;
  clickUpdateImgText: string;
  imgUrl;
  _uploadId;
  _type;
  @Output()
  imgData = new EventEmitter();

  /**
   * 将获取的上传ID传入
   */
  @Input()
  set uploadId(uploadId) {
    if ( uploadId ) {
      this._uploadId = uploadId;
    }
  }
  @Input()
  set type( type ) {
    if ( type ) {
      this._type = type;
      switch (type) {
        case '1':
          this.uploadingImgText = '点击上传Banner图片';
          this.clickUpdateImgText = '点击修改Banner图片';
          break;
        case '8':
          this.uploadingImgText = '点击上传栏目图片';
          this.clickUpdateImgText = '点击修改栏目图片';
          break;
      }
    }
  }
  // 将数据传入 显示图片  也可以用于清空
  @Input()
  set updateData(updateData) {
    if ( updateData ) {
      this.imgUrl = updateData;
    }
  }
  constructor(
    public $domSan: DomSanitizer,
    public $message: ModalService,
    private $uploadService: UploadService,
  ) { }
  ngOnInit() {}
  clickFile() {
    const uploadingImg: any = document.querySelector('input#uploadingImg');
    uploadingImg.click();
  }
  fileChange() {
    const fileNode = document.getElementById('uploadingImg');
    const fileName = fileNode['files'][0].name;
    const reg = /(.jpg|.png|.jpeg|.gig)$/i;
    if (reg.test(fileName)) {
      const file = fileNode['files'][0];
      const tempImg = new Image();
      tempImg.src = window.URL.createObjectURL(fileNode['files'][0]);
      setTimeout(() => {
        if ( tempImg.width >= 655 && tempImg.height >= 302 ) {
          this.$uploadService.upload(this._type, file).then((url: string) => {
            this.imgUrl = window.URL.createObjectURL(fileNode['files'][0]);
            this.imgData.emit(url);
          });
        } else {
          this.$message.info('图片尺寸太小！');
        }
      }, 100);
      }
  }

}
