export default {
  add: 'add',
  update: 'update',
  delete: 'delete',
  moveUp: 'moveUp',
  moveDown: 'moveDown',
  cancel: 'cancel',
  okTest: 'okTest',
  addBanner: 'addBanner',
  updateBanner: 'updateBanner',
  areYouSureToDeleteTheBanner: 'Are you sure to delete the Banner?',
  afterDeletionItWillNotBeRecoverablePleaseBeCarefulBanner: 'After deletion, it will not be recoverable. Please be careful Banner',
  yes: 'yes',
  no: 'no',
  addition: 'addition',
  remove: 'remove',
  SureToRemoveResources:  'Sure to remove resources?',
  SureToRemoveResourcesAfterRemovalItCannotBeRestoredPleaseBeCareful:
    'Sure to remove resources? After removal, it cannot be restored. Please be careful',
  addColumn: 'addColumn',
  updateColumn: 'updateColumn',
  areYouSureToDeleteThisColumn: 'Are you sure to delete this column?',
  columnDeletedWillNotBeRestoredPleaseBeCarefulOperation: 'Column deleted, will not be restored, please be careful operation',
  operation: 'operation',
  author: 'author',
  resourceType: 'resourceType',
  isNoShelf: 'isNoShelf',
  showStyle: 'showStyle',
  isNoHot: 'isNoHot',
  isNoRecommend: 'isNoRecommend',
  columnName: 'columnName',
  isNoShow: 'isNoShow',
  resourceCover: 'resourceCover',
  resourceName: 'resourceName',
  graphicList: 'Graphic List',
  transverseShuffling: 'Transverse Shuffling',
  BannerMap: 'Banner Map',
  theTheme: 'The Theme',
  answerType: 'answer Type',
  associatedResources: 'associated Resources',
  initiator: 'initiator',
  activityTime: 'activity Time',
  numberOfParticipants: 'number Of Participants',
  readingScores: 'reading Scores',
  userName: 'user Name',
  grade: 'grade',
  questionNumber: 'question Number',
  theme: 'theme',
  timeToMarket: 'time to market',
  addTopic: 'add topic',
  topic: 'topic',
  referenceVersion: 'reference Version',
  classification: 'classification',
  topicType: 'topic Type',
  issueImg: 'issue Img',
  contentOfFeedback: 'content Of Feedback',
  cellPhoneNumber: 'cell Phone Number',
  feedbackTime: 'feedback Time',
  isNoDispose: 'is No Dispose',
  batchShow: 'batch Show',
  areYouSureToDeleteTheComment: 'Are you sure to delete the comment?',
  commentsDeletedWillNotBRPBC: 'Comments deleted will not be recoverable, please be careful',
  columnThumbnail: 'Column thumbnail',
  firstClass: 'first Class',
  workTogetherToWinReadingPoints: 'Work together to win reading points',
  returnList: 'return List',
  didNotScore: 'did Not Score',
  hasScore: 'has Score',
  submitMyGrade: 'Submit my grade',
  score: 'score',
  openComments: 'open Comments',
  objectiveItem: 'objective item',
  newActivityToAnswerQuestions: 'New activity to answer questions',
  updateActivityToAnswerQuestions: 'update Activity To Answer Questions',
  issue: 'issue',
  subjectiveItem: 'subjective Item',
};
