export default {
  confirm: '确定',
  cancel: '取消',
  options: '选项',
  saving: '保存中...',
  loading: '加载中',
  operate: '操作',
  operateSuccess: '操作成功',
  more: '更多',
  serialNumber: '序号',
  reset: '重置',
  selected: '已选',
  unSelect: '去选',
  count: '条',
  noData: '无数据',
  choosePosition: '选择地理位置',
  levelLimit: '最多只能增加5级！',
  levelEdit: '有子级不能改变原来的级别！',
  setAreaMsg: '不可解除当前区域已关联的设施，会造成该设施无区域归属！',
  deleteBtn: '删除',
  edit: '编辑',
  revert: '撤回',
  assign: '指派',
  viewPhoto: '查看图片',
  beOffLineMsg: '你已被强制下线！',
  beDeleteMsg: '你登录的账号已被强制删除！',
  turnBackConfirm: '退单确认',
  rebuild: '重新生成',
  nameRuleMsg: '只能输入中文、英文、数字、空格和常用符号！',
  notEmpty: '输入值不能全为空格！',
  notNum: '请输入数字！',
  permissionMsg: '您暂无操作权限！',
  expectCompleteTimeMoreThanNowTime: '期望完工时间应大于当前时间',
  nameExists: '此名称已经存在！',
  phoneNumberExists: '此手机号已经存在！',
  emailExists: '此邮箱已经存在！',
  cancelText: '取消',
  okText: '确定',
  logOutMsg: '是否注销？',
  openLockMsg: '开锁消息',
  systemMsg: '后台消息',
  clickSelect: '请点击选择',
  deviceBeOffLineMsg: '该账号已在其他设备登录！',
  addressRequired: '详细地址为必填项！',
  noPictureNow: '暂无图片',
  one: '一',
  two: '二',
  three: '三',
  four: '四',
  five: '五',
  level: '级',
  tempAuthMsg: '有新的临时授权需要审核！',
  timeMsg: '开始时间不能大于结束时间，请重新选择！',
};
