import {ComponentRef, Injectable} from '@angular/core';
import {OverlayRef, Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {SelectLiveRoomComponent} from './select-live-room.component';

@Injectable()
export class SelectLiveRoomService {

  private selectBookSingle: ComponentRef<SelectLiveRoomComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  select(filters, selected) {
    return new Promise((resolve) => {
      this.overlayRef = this.overlay.create();
      this.selectBookSingle = this.overlayRef.attach(new ComponentPortal(SelectLiveRoomComponent));
      this.selectBookSingle.instance.showSelectModel(filters, selected);
      this.selectBookSingle.instance.selectChange.subscribe((data) => {
        resolve(data);
      });
      this.selectBookSingle.instance.modelCancel.subscribe(() => {
        this.selectBookSingle.destroy();
        resolve(null);
      });
    });
  }
}
