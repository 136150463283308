export default {
  'add': 'add ',
  'update': 'update ',
  'view': 'view ',
  'business': 'business',
  'columns': 'columns',
  'columnList': 'columnList',
  'column': 'column',
  'resources': 'resources',
  'resourceList': 'resourceList',
  'resource': 'resource',
  'packageResource': 'packageResource',
  'packageResourceList': 'packageResourceList',
  'packageResourceParticulars': 'packageResourceParticulars',
  'pictureBookParticulars': 'pictureBookParticulars',
  'OrderParticulars': 'OrderParticulars',
  'home': 'home',
  'preferentialCoupon': 'preferentialCoupon',
  'resourceParticulars': 'resourceParticulars',
  'eBookResourceList': 'eBookResourceList',
  'preferentialActivityList': 'preferentialActivityList',
  'pictureBookList': 'PictureBookList',
  'VirtualOrderList': 'VirtualOrderList',
  'VirtualOrderParticulars': 'VirtualOrderParticulars',
  'PhysicalOrderList': 'PhysicalOrderList',
  'eCommerceManage': 'eCommerceManage',
  'integralExchangeList': 'integralExchangeList',
  'integralExchangeDetail': 'integralExchangeDetail',
  'integralExchange': 'integralExchange',
  'PreferentialActivityParticulars': 'PreferentialActivityParticulars',
  'RefundOrderParticulars': 'RefundOrderParticulars',
  'commodity': 'commodity',
  'commodityParticulars': 'commodityParticulars',
  'vipMemberManagement': 'vipMemberManagement',
  'commodityList': 'commodityList',
  'couponManagement': 'couponManagement',
  'vipType': 'vipType',
  'coupon': 'coupon',
  'vipTypeManagement': 'vipTypeManagement',
  'lotteryList': 'lotteryList',
  'userStatistics': 'userStatistics',
  'lotteryParticulars': 'lotteryParticulars',
  'lotteryActivity': 'addLotteryActivity',
  'pictureBook': 'pictureBook',
  'eBookResource': 'eBookResource',
  'eBookResourceParticulars': 'eBookResourceParticulars',
  'evaluates': 'evaluates',
  'evaluateList': 'evaluateList',
  'evaluate': 'evaluate',
  'classifyManagement': 'classifyManagement',
  'classifyManagementList': 'classifyManagementList',
  'gradingManagement': 'gradingManagement',
  'gradingManagementList': 'gradingManagementList',
  'users': 'users',
  'userList': 'userList',
  'user': 'user',
  'bannerManage':  'bannerManage',
  'activity':  'activity',
  'rewardActivityList':  'rewardActivityList',
  'rewardActivity':  'rewardActivity',
  'winnerList':  'winnerList',
  'columnConfiguration': 'columnConfiguration',
  'commentManagement': 'commentManagement',
  'mysteriousAnswer': 'mysteriousAnswer',
  'userInfo': 'userInfo',
  'roles': 'roles',
  'mobileList':'mobileList',
  'roleList': 'roleList',
  'qrManager': 'qrManager',
  'opinionFeed': 'opinionFeed',
  'videoResourceList': 'videoResourceList',
  'videoResource': 'videoResource',
  'videoResourceParticulars': 'videoResourceParticulars',
  'system': 'system',
  'systemLog': 'systemLog',
  'operationLog': 'operationLog',
  'diversionStatistics': 'diversionStatistics',
  'firstLessonStatistics': 'firstLessonStatistics',
  'moduleUsageStatistics': 'moduleUsageStatistics',
  'bookReviewList': 'bookReviewList',
  'bookReviewDetail': 'bookReviewDetail',
  'memberList': 'memberList',
  'appraisalList': 'appraisalList',
  'evaluateInformation': 'evaluateInformation',
  'information': 'information',
  'thematic': 'thematic',
  'thematicManage': 'thematicManagement',
  'aloneEvaluate': 'aloneEvaluate',
  'questionDetail': 'questionDetail',
  'iLikeReading':'iLikeReading'
};
