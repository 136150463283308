import {Component, Input, OnInit} from '@angular/core';
import {NzI18nService} from 'ng-zorro-antd';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-view-picture',
  templateUrl: './view-picture.component.html',
  styleUrls: ['./view-picture.component.scss']
})
export class ViewPictureComponent implements OnInit {

  @Input() qrCodeSrc: string = '';
  @Input() fileName = '';
  // 国际化
  language: any = {};
  // 控制model显示隐藏
  qrCodeVisible = false;
  imgSrc: SafeUrl;
  constructor(
    private sanitizer: DomSanitizer,
    public $nzI18n: NzI18nService) {
    this.language = $nzI18n.getLocaleData('resourceManage');
  }

  ngOnInit() {
    this.imgSrc =  this.sanitizer.bypassSecurityTrustUrl(this.qrCodeSrc);
  }

  /**
   * 下载二维码
   */
  downloadQrCode() {
    const qrCodeDown = document.createElement('a');
    qrCodeDown.download = `${this.fileName}.png`;
    qrCodeDown.href = this.qrCodeSrc;
    qrCodeDown.click();
  }

  /**
   * 关闭按钮
   */
  close() {
    this.qrCodeVisible = false;
  }
}
