import {ComponentRef, Injectable} from '@angular/core';
import {OverlayRef, Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {PictureBookImgComponent} from './picture-book-img.component';

@Injectable()
export class PictureBookImgService {

  private pictureBookImgComponent: ComponentRef<PictureBookImgComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  select(imagePath, type) {
    return new Promise((resolve) => {
      this.overlayRef = this.overlay.create();
      this.pictureBookImgComponent = this.overlayRef.attach(new ComponentPortal(PictureBookImgComponent));
      this.pictureBookImgComponent.instance.InitImg(imagePath, type);
      this.pictureBookImgComponent.instance.imageChange.subscribe((data) => {
        this.pictureBookImgComponent.destroy();
        resolve(data);
      });
      this.pictureBookImgComponent.instance.cancel.subscribe(() => {
        this.pictureBookImgComponent.destroy();
        resolve(null);
      });
    });
  }
}
