export default {
  'setColumn': '设置列',
  'SelectAllRow': '选中所有行',
  'SelectOddRow': '选中偶数行',
  'SelectEvenRow': '选中奇数行',
  'Total': '共',
  'items': '条',
  'filter': '筛选',
  'prompt': '提示',
  'promptContent': '是否确认删除？',
  'okText': '确定',
  'cancelText': '取消',
  'openText': '开',
  'closeText': '关',
  'noData': '暂无数据',
  'add': '新增',
  'delete': '删除',
  'operate': '操作',
  'update': '修改',
  'exportTemp': '选择导出格式',
  'export': '导出',
  'print': '打印',
  'search': '搜索',
  'searchTemp': '按模版搜索',
  'reset': '重置',
  'gt': '大于',
  'lt': '小于',
  'eq': '等于'
};
