import {Component, Input, OnInit} from '@angular/core';
import {CommonUtil} from '../../util/common-util';

@Component({
  selector: 'app-topic-info',
  templateUrl: './topic-info.component.html',
  styleUrls: ['./topic-info.component.scss']
})
export class TopicInfoComponent implements OnInit {
  // 题目信息
  @Input() topicInfo: any;
  // 图片地址
  uploadUrl = CommonUtil.getUploadUrl();
  constructor() { }

  ngOnInit() {
  }

}
