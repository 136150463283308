import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'answerPipe'
})
export class AnswerPipe implements PipeTransform {

  constructor () {
  }
  transform(value) {
    let str = 'A';
    switch (value) {
      case 1:
        str = 'A';
        break;
      case 2:
        str = 'B';
        break;
      case 3:
        str = 'C';
        break;
      case 4:
        str = 'D';
        break;
    }
    return str;
  }

}
