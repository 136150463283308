import {Component, EventEmitter, Output} from '@angular/core';

/**
 * modal弹框
 */
@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss']
})
export class InfoAlertComponent {
  /**
   * 关闭，用于销毁弹框
   */
  @Output() cancel = new EventEmitter<any>();
  /**
   * 页面属性，用于区分弹出框
   */
  public infoType;
  /**
   * 弹框标题
   */
  public title;
  /**
   * 弹框内容
   */
  public content;
  /**
   * 弹框显示
   */
  public xcVisible = false;
  /**
   * 确定按钮
   */
  public handleOk: Function = () => {};
  constructor() { }

  /**
   *确定操作对话框
   */
  public confirmModal(data: {modalTitle, modalContent?, handleOk?()}) {
      this.infoType = 'confirm';
      this.title = data.modalTitle;
      this.content = data.modalContent ? data.modalContent : '';
      this.handleOk = data.handleOk ? data.handleOk : this.handleCancel;
      this.xcVisible = true;
  }

  /**
   * 删除警告框
   */
  public errorModal(data: {modalTitle, modalContent?, handleOk?()}) {
    this.infoType = 'error';
    this.title = data.modalTitle;
    this.content = data.modalContent ? data.modalContent : '';
    this.handleOk = data.handleOk ? data.handleOk : this.handleCancel;
    this.xcVisible = true;
  }
  public warnModal(data: {modalTitle, modalContent?, handleOk?()}) {
    this.infoType = 'warn';
    this.title = data.modalTitle;
    this.content = data.modalContent ? data.modalContent : '';
    this.handleOk = data.handleOk ? data.handleOk : this.handleCancel;
    this.xcVisible = true;
  }
  public successModal(data: {modalTitle, modalContent?, handleOk?()}) {
    this.infoType = 'success';
    this.title = data.modalTitle;
    this.content = data.modalContent ? data.modalContent : '';
    this.handleOk = data.handleOk ? data.handleOk : this.handleCancel;
    this.xcVisible = true;
  }
  /**
   * 页面取消
   */
  public handleCancel() {
    this.xcVisible = false;
  }

  /**
   * 确定按钮
   */
  public clickOk() {
    if (this.handleOk) {
      this.handleOk();
    }
    this.xcVisible = false;
  }
}
