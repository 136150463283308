import { ComponentRef, Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import { PictureExhibitionComponent } from '../../component/picture-exhibition/picture-exhibition.component';

@Injectable()
export class PictureExhibitionService {
  private PictureExhibitionMusic: ComponentRef<PictureExhibitionComponent> | any;
  private overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  start(src) {
    this.overlayRef = this.overlay.create();
    this.PictureExhibitionMusic = this.overlayRef.attach(new ComponentPortal(PictureExhibitionComponent));
    this.PictureExhibitionMusic.instance.pictureExhibitionInto(src);
    this.PictureExhibitionMusic.instance.close.subscribe(() => {
      this.PictureExhibitionMusic.destroy();
    });
  }

}
