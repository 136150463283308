export enum BannerTemplateType {
  INFO = 1,
  LIST = 2,
  URL = 3,
  INSTATION = 4,
  MICRO002 = 5
}

export enum ColumnTemplateType {
  AXIS = 1,
  LIST = 2,
  URL = 3,
  INSTATION = 4,
  AUTHOR = 5
}

export enum BannerType {
  INDEX = 1,
  PICTUREBOOK = 2,
  ILIKEREADING = 3
}
