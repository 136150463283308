import { Component, OnInit } from '@angular/core';
import Viewer from 'viewerjs';
@Component({
  selector: 'app-viewerjs',
  templateUrl: './viewerjs.component.html',
  styleUrls: ['./viewerjs.component.scss']
})
export class ViewerjsComponent implements OnInit {

  public imageList = [];
  public viewer: any;
  constructor() { }
  ngOnInit() {
    setTimeout(() => {
      if (this.imageList.length > 0) {
        const node = document.getElementById('images');
        this.viewer = new Viewer(node, {
          title: () => '',
          hide() {
            node.parentNode.removeChild(node);
          }
        });
        const imgList: any = document.getElementsByClassName('img-list');
        imgList[0].click();
      }
    });
  }

}
