export default {
  okTest: '确定',
  cancel: '取消',
  add: '新增',
  addEvaluate: '新增测评',
  edit: '编辑',
  editEvaluate: '编辑测评',
  delete: '删除',
  areYouSureToDeleteTheEvaluation: '确定删除该测评？',
  evaluationDeletedWillNotBeRestoredPleaseBeCarefulOperation: '测评删除后，将不可恢复，请谨慎操作',
  addTopic: '新增题目',
  updateTopic: '编辑题目',
  sureToDeleteTheSubject: '确定删除该题目？',
  afterTheTopicIsDeletedItCannotBeRestoredPleaseBeCareful: '题目被删除后，将不可恢复，请谨慎操作',
  warmReminderPleaseLightUp: '温馨提示：请点亮',
  markTheCorrectAnswer: '标识正确答案',
  pleaseEnterOptionsOne: '请输入选项1',
  pleaseEnterOptionsTwo: '请输入选项2',
  pleaseEnterOptionsThree: '请输入选项3',
  pleaseEnterOptionsFour: '请输入选项4',
};
