import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonConfig} from '../../util/common-config';

@Component({
  selector: 'app-statistics-title',
  templateUrl: './statistics-title.component.html',
  styleUrls: ['./statistics-title.component.scss']
})
export class StatisticsTitleComponent implements OnInit, OnChanges {
  @Input() pageTitle = '';
  @Input() showGrade = false;
  // @Input() showBack = false;
  // _showBack = false;
  @Input() showBack;
  @Output() gradeEmit = new EventEmitter();
  @Output() backEmit = new EventEmitter();
  // @Output() showBackChange = new EventEmitter();
  grade = 1;
  gradeList = [];
  constructor() { }

  ngOnInit() {
    this.gradeList = CommonConfig.getGradeList();
  }

  ngOnChanges(changes: SimpleChanges): void {


  }

  // get showBack() {
  //   return this._showBack;
  // }
  // public change() {
  //   this.showBackChange.emit(this.showBack);
  // }
  gradeChange() {
    this.gradeEmit.emit(this.grade);
  }
  backChange() {
    this.backEmit.emit();
  }
}
