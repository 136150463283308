import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NzI18nService} from 'ng-zorro-antd';
import {EvaluateColumnConfigService} from '../../../business-module/evaluate/evaluate-column-config.service';
import {CommonConfig} from '../../util/common-config';
import {CommonUtil} from '../../util/common-util';
import axios from '../../../core-module/http/http.service';
import {Result} from '../../entity/result';
import {TableBasic} from '../table/table.basic';

@Component({
  selector: 'app-select-book-single',
  templateUrl: './select-book-single.component.html',
  styleUrls: ['./select-book-single.component.scss']
})
export class SelectBookSingleComponent extends TableBasic implements OnInit {
  // 控制model显示隐藏
  xcVisible = true;
  // model框标题
  linkData;
  // 关联数据id，用于单选框回显
  selectedId;
  // 选择的数据
  selectData;
  // 外部查询条件
  filterConditions;
  // 国际化
  language: any;
  // 隐藏model事件
  @Output() modelCancel = new EventEmitter();

  // 隐藏model事件
  @Output() selectChange = new EventEmitter();
  @ViewChild('imgTemp') public imgTemp;  // 封面图片组件
  @ViewChild('resourceLinkName') public resourceLinkName;  // 资源名称组件
  @ViewChild('radioTemp') public radioTemp;  // 单选框组件
  constructor(
    public $router: Router,
    public $nzI18n: NzI18nService,
    public $columnConfigService: EvaluateColumnConfigService,
  ) {
    super($nzI18n);
    this.language = $nzI18n.getLocaleData('resourceManage');
  }

  ngOnInit() {
    this.initTableConfig();
  }

  /**
   * 显示关联资源弹框
   * param filters
   * param selected
   */
  showSelectModel(filters, selected) {
    this.xcVisible = true;
    this.filterConditions = filters;
    this.selectedId = selected;
    this.refreshData();
  }
  /**
   * 初始化表格配置
   */
  public initTableConfig() {
    this.tableConfig = {
      isDraggable: false,
      isLoading: true,
      showSearchSwitch: true,
      showSizeChanger: true,
      scroll: {x: '600px', y: '340px'},
      noIndex: true,
      showSearchExport: false,
      showPagination: true,
      columnConfig: [
        {
          type: 'render', fixedStyle: {fixedLeft: true, style: {left: '0px'}}, width: 42,
          renderTemplate: this.radioTemp
        },
        {
          title: this.language.resourceCover,
          type: 'render',
          renderTemplate: this.imgTemp,
          width: 105
        },
        {
          title: this.language.resourceName, key: 'resourceName', width: 120,
          type: 'input',
          searchable: true,
          searchConfig: {type: 'input'},
        },
        {
          title: this.language.resourceCategory, key: 'resourceTypeName', width: 170,
          searchKey: 'resourceType',
          searchable: true,
          searchConfig: {
            type: 'select',
            selectType: 'multiple',
            selectInfo: CommonConfig.getResourceTypeList()
          }
        }],
      bordered: false,
      notShowPrint: true,
      showSearch: false,
      operation: [],
      leftBottomButtons: [],
      rightTopButtons: []
    };
  }

  /**
   * 确定按钮发送勾选数据到父组件
   */
  public linkChange() {
    this.linkData = CommonUtil.deepClone(this.selectData);
    this.selectedId = this.linkData.id;
    this.xcVisible = false;
    this.selectChange.emit(this.linkData);
    this.modelCancel.emit();
  }


  refreshData() {
    this.queryCondition.filterConditions.push(...this.filterConditions);
    axios.post('/ksr_manager/resourceManager/queryResourceInfoList', this.queryCondition).then((result: Result) => {
      if (result.code === '0') {
        this.tableConfig.isLoading = false;
        result.data.data.map((item) => {
          item.gradeNameList = item.gradeList.map(inx => inx.gradeName) || ['--'];
        });
        const selectDataList = result.data.data.filter(item => item.id === this.selectedId);
        if (selectDataList && selectDataList.length > 0) {
          this.selectData = selectDataList[0];
        }
        this._dataSet = result.data.data;
        this.pageBean.Total = result['data'].totalCount;
        this.pageBean.pageSize = result['data'].size;
        this.pageBean.pageIndex = result['data'].pageNum;
      }
    });
  }

  /**
   * 取消按钮关闭modal框
   */
  public linkCancel() {
    if (this.linkData) {
      this.selectedId = this.linkData.id;
    } else {
      this.selectedId = '';
    }
    this.xcVisible = false;
    this.modelCancel.emit();
  }

  /**
   * 单选框点击
   */
  public radioClick(data) {
    this.selectData = data;
  }

}
