import {Injectable} from '@angular/core';
import {FormItem} from '../form/form-config';
import {RuleUtil} from '../../util/rule-util';

@Injectable()
export class AddTopicConfigService {
  /**
   * 测评管理 新增和编辑 题目
   */
  addTopicFormConfig(chooseA, chooseB, chooseC, chooseD ): FormItem[] {
    const formColumn: FormItem[] = [
      {
        label: '题目',
        key: 'stem',
        type: 'textarea',
        col: 36,
        labelWidth: 60,
        placeholder: '请输入题目',
        resize: true,
        require: true,
        rule: [{ required: true }, { maxLength: 200 }, RuleUtil.getNamePatternRule()],
      },
      {
        label: '选项1',
        key: 'chooseA',
        type: 'custom',
        labelWidth: 60,
        col: 36,
        require: true,
        rule: [{ required: true }, RuleUtil.getNamePatternRule()],
        template: chooseA,
      },
      {
        label: '选项2',
        key: 'chooseB',
        type: 'custom',
        col: 36,
        require: true,
        rule: [{ required: true }, RuleUtil.getNamePatternRule()],
        template: chooseB,
        // rule: [
        //   {required: true},
        //   { maxLength: 50 }]
      },
      {
        label: '选项3',
        key: 'chooseC',
        type: 'custom',
        col: 36,
        rule: [],
        require: false,
        template: chooseC,
        // rule: [{ maxLength: 50 }],
      },
      {
        label: '选项4',
        key: 'chooseD',
        type: 'custom',
        col: 36,
        rule: [],
        require: false,
        template: chooseD,
        // rule: [{ maxLength: 50 }],
      }
    ];
    return formColumn;
  }

  /**
   * 神秘问题 的表单
   */
  addMysteriousFormConfig(numTemp, chooseA, chooseB, chooseC, chooseD ): FormItem[] {
  const formColumn: FormItem[] = [
    {
      label: '题目',
      key: 'stem',
      type: 'textarea',
      col: 36,
      labelWidth: 60,
      placeholder: '请输入题目',
      resize: true,
      require: true,
      rule: [{ required: true }, { maxLength: 200 }],
    },
    {
      // 分数
      label: '分数(分)',
      key: 'grade',
      labelWidth: 60,
      type: 'custom',
      col: 36,
      require: true,
      template: numTemp,
      rule: [{ required: true }],
    },
    {
      label: '选项1',
      key: 'chooseA',
      type: 'custom',
      col: 36,
      labelWidth: 60,
      require: true,
      rule: [{ required: true }],
      template: chooseA,
    },
    {
      label: '选项2',
      key: 'chooseB',
      type: 'custom',
      col: 36,
      labelWidth: 60,
      require: true,
      rule: [{ required: true }],
      template: chooseB,
      // rule: [
      //   {required: true},
      //   { maxLength: 50 }]
    },
    {
      label: '选项3',
      key: 'chooseC',
      type: 'custom',
      col: 36,
      labelWidth: 60,
      rule: [],
      require: false,
      template: chooseC,
      // rule: [{ maxLength: 50 }],
    },
    {
      label: '选项4',
      key: 'chooseD',
      type: 'custom',
      col: 36,
      labelWidth: 60,
      rule: [],
      require: false,
      template: chooseD,
      // rule: [{ maxLength: 50 }],
    }
  ];
  return formColumn;
}
}
