import {ComponentRef, Injectable} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {OverlayRef, Overlay} from '@angular/cdk/overlay';
import {AuthorSelectComponent} from './author-select.component';

@Injectable()
export class AuthorSelectService {

  private resourceSelectService: ComponentRef<AuthorSelectComponent> | any;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  /**
   * 关联资源
   */
  selectAuthor(selectedList, filter?) {
    return new Promise((resolve, reject) => {
      this.overlayRef = this.overlay.create();
      this.resourceSelectService = this.overlayRef.attach(new ComponentPortal(AuthorSelectComponent));
      this.resourceSelectService.instance.authorInto(selectedList, filter);
      this.resourceSelectService.instance.resourceListChange.subscribe( (resourceList) => {
        resolve(resourceList);
        this.resourceSelectService.destroy();
      });
    });
  }
}
