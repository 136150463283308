import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VideoBlot} from './video-blot';
import Quill from 'quill';
Quill.register(VideoBlot);
@Component({
  selector: 'app-common-edit',
  templateUrl: './common-edit.component.html',
  styleUrls: ['./common-edit.component.scss']
})
export class CommonEditComponent implements OnInit {
  // 编辑器高度
  @Input() height: number = 500;
  // 编辑器宽电压
  @Input() width: number = 400;
  // 文本框未输入时提示
  @Input() placeholder: string = '请输入';
  @Output() contentChange = new EventEmitter();
  // 文本框内容
  @Input() content: string = '';
  // 上传视频显示隐藏
  isVisible: boolean = false;

  editor: any;
  constructor() {
  }

  ngOnInit() {
  }

  /**
   * 监听编辑器创建完成事件
   * param event
   */
  editorCreated(event) {
    const toolbar = event.getModule('toolbar');
    toolbar.addHandler('video', this.videoHandler.bind(this));
    this.editor = event;
  }

  /**
   * 点击上次视频
   */
  videoHandler() {
    this.isVisible = true;
  }

  /**
   * 插入视频
   * param videoInfo
   */
  insertVideo(videoInfo) {
    this.isVisible = false;
    const range = this.editor.getSelection();
    const newRange = 0 + (range !== null ? range.index : 0)
    this.editor.insertEmbed(newRange, 'simpleVideo', videoInfo);
  }
  /**
   * 监听输入框变化
   * param event
   */
  contentChanged(event) {
    this.contentChange.emit(event.html);
  }

  /**
   * 清空输入区域
   */
  clear() {
    this.content = '';
  }

  /**
   * video取消上传
   */
  videoCancel() {
    this.isVisible = false;
  }

}
