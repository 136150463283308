import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UploadService} from '../../util/upload.service';
import {CommonUtil} from '../../util/common-util';

@Component({
  selector: 'app-picture-book-img',
  templateUrl: './picture-book-img.component.html',
  styleUrls: ['./picture-book-img.component.scss']
})
export class PictureBookImgComponent implements OnInit {
  // 图片地址
  uploadUrl = CommonUtil.getUploadUrl();
  isVisible = false;
  imgPath = '../../../../assets/img/uploading.png';
  uploadType = 23;
  // 取消
  @Output() cancel = new EventEmitter();
  // 图片变化
  @Output() imageChange = new EventEmitter();
  constructor(private $uploadService: UploadService) { }

  ngOnInit() {
  }

  InitImg(imgPath, type) {
    this.isVisible = true;
    this.imgPath = imgPath;
    this.uploadType = type;
  }

  handleCancel() {
    this.cancel.emit();
  }

  handleOk() {
    this.imageChange.emit(this.imgPath);
  }

  /**
   * 上传文件
   */
  handleUpload() {
    this.$uploadService.selectFile(['.png', '.jpg']).then(file => {
      this.$uploadService.upload(this.uploadType, file).then((url: string) => {
        this.imgPath = url;
      });
    });
  }
}
