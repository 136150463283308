import { Injectable } from '@angular/core';
import axios from 'axios';
import {MessageModalService} from '../component/info-alert/message-modal.service';

@Injectable()
export class DownloadService {

  constructor(private $modalService: MessageModalService) { }
  down(name, url) {
    axios.get(url, {
      responseType: 'blob'
    }).then((result: any) => {
      const blob = new Blob([result], {type: 'application/octet-stream'});
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, name);
      } else {
        const a = document.createElement('a');
        a.download = name;
        a.href = URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }, () => {
      this.$modalService.warn({
        modalTitle: '文件下载失败'
      });
    });
  }

  /**
   * 导出下载
   * param name
   * param blobData
   */
  downBlob(name, blobData: any) {
    const blob = new Blob([blobData], {type: 'application/octet-stream'});
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
      const a = document.createElement('a');
      a.download = name;
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}
